import cn from 'classnames';
import { Drawer, Form, Layout, Core, Localization } from 'connex-cds';
import { find, isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useReasonCodeSetup, useVehicleSetup } from './MasterDataProvider';
import { useTicketContext } from './TicketContext';

const Styled = styled.div`
  padding-top: 24px;
  .reason {
    border: var(--color-border-container);
    background-color: var(--color-background-container);
    margin-bottom: 10px;
    &.active {
      background-color: #d9d9d9;
    }
  }
`;

export const DriverAcceptance = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const {
    Components: { SubmitButton },
    values,
    setFieldValue,
    errors,
  } = Form.useFormContext();

  const { closeDrawer } = Drawer.useDrawerContext();

  const { ticket } = useTicketContext();

  const vehicleSetup = useVehicleSetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const companySetup = useCompanySetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup?.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [ticket?.vehicleId, vehicleSetup?.data]);

  const activeReasons = React.useMemo(
    () =>
      reasonCodeSetup?.data
        ?.filter?.(d => d.type === 'SIGNATURE')
        ?.filter?.(reason => reason.status === 'ACTIVE')
        ?.filter?.(reason =>
          companySetup?.data?.isMulticountry === true
            ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
            : reason
        )
        ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length) || [],
    [companySetup?.data?.isMulticountry, reasonCodeSetup?.data, ticket?.origin?.address?.countryCode, vehicleType]
  );

  const options = React.useMemo(() => {
    if (!activeReasons?.length) {
      return [];
    }

    const reasonCodes = translateObjects(activeReasons, {
      getStringId: x => `SETUP_REASON-CODE_${x}`.replace(/\s/gi, '-').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'description',
    });

    if (!reasonCodes) {
      return activeReasons;
    }

    return reasonCodes.map(reason => ({ id: reason?.crn, label: reason?.description ?? reason?.name, isDefault: reason?.isDefault }));
  }, [activeReasons]);

  // using default option as a selected option
  const defaultOption = React.useMemo(() => {
    return options.filter(option => option.isDefault)
  })

  const disabled = React.useMemo(() => {
    return !isEmpty(omit(errors, 'ticketEvents')) || !values?.driverAcceptanceReason;
  }, [errors, values?.driverAcceptanceReason]);

  React.useEffect(() => {
    if (values?.driverAcceptanceReason) {
      setFieldValue('customerStatus', 'ACCEPTED');
    }
  }, [setFieldValue, values?.driverAcceptanceReason, disabled]);

  React.useEffect(() => {
    if(defaultOption.length >= 1) {
      setFieldValue('driverAcceptanceReason', find(reasonCodeSetup.data, { crn: defaultOption[0].id }));
    }
  }, [])

  const handleSave = React.useCallback(
    reason => {
      const targetReason = find(reasonCodeSetup.data, { crn: reason });
      setFieldValue('driverAcceptanceReason', targetReason);
      setFieldValue('signature', undefined);
    },
    [reasonCodeSetup.data, setFieldValue]
  );

  return (
    <Styled className={cn('driver-acceptance')}>
      <Core.Spinner spin={vehicleSetup.isLoading || reasonCodeSetup.isLoading}>
        {options.map(option => {
          return (
            <div
            className={cn('reason', { active: values?.driverAcceptanceReason?.crn === option.id })}
            style={{
                padding: '3px 10px',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
              onClick={() => {
                handleSave(option.id);
              }}
            >
              {option.label}
            </div>
          );
        })}
        <SubmitButton
          stringId="submitAcceptance"
          data-testid="submit-driver-acceptance-button"
          onClick={closeDrawer}
          disabled={disabled}
        />
      </Core.Spinner>
    </Styled>
  );
};
