import common from '../common';
import errors from './errors';
import themes from './theme';
import features from './features';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Lagre',
  username: 'Brukernavn',
  password: 'Passord',
  login: 'Logg inn',
  required: 'Obligatorisk',
  light: 'Lyst',
  dark: 'Mørkt',
  rememberMe: 'Husk meg',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Personvernerklæring',
  customer: 'Personvernerklæring',
  project: 'Prosjekt',
  isBillable: 'Fakturerbar',
  dispatchDateTime: 'Dato',
  orderId: 'Ordre',
  ticketId: 'Følgeseddel',
  location: 'Lokasjon',
  vehicleTypeName: 'Kjøretøy',
  product: 'Produkt',
  products: 'Produkt',
  salesTax: 'Merverdiavgift',
  totalPrice: 'Total',
  extendedPrice: 'Økt pris',
  destination: 'Desitnasjon',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Kjøretøystype ID',
  locationId: 'Lokasjon ID',
  customerId: 'Kunde ID',
  projectId: 'Prosjekt ID',
  dispatchTime: 'Tid',
  showAll: 'Vis alle',
  hideAll: 'Skjul alle',
  undoChanges: 'Angre endringer',
  resetColumns: 'Tilbakestill kolonner',
  displayRowCount: `Viser {count, plural, 
    =0 {0 rader} 
    one {# rad} 
    other {# rader} 
  }`,
  columnFilters: 'Kolonnefilter',
  visible: 'Synlig',
  hidden: 'Skjult',
  itemDetail: 'Varedetaljer',
  quantity: 'Mengde',
  unloadingMethod: 'Lossemetode',
  time: 'TId',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Kubikkmeter',
  M3_text: 'Kubikkmeter',
  EA_text: 'Hver',
  LTR_text: 'Liter',
  YDQ_text: 'Kubikkmeter',
  GLL_text: 'Liter',
  ACR_text: 'Dekar',
  HUR_text: 'Time',
  INH_text: 'Tommer',
  FOT_text: 'Fot',
  YRD_text: 'Hage',
  MMT_text: 'Millimeter',
  CMT_text: 'Centimeter',
  MTR_text: 'Måler',
  SMI_text: 'Mile (Statut Mile)',
  KMT_text: 'Kilometer',
  INK_text: 'Kvadrat tomme',
  FTK_text: 'Kvadratfot',
  YDK_text: 'Firkantet hage',
  MIK_text: 'Kvadratkilometer',
  CMK_text: 'Kvadrat centimeter',
  MTK_text: 'Kubikkmåler',
  KMK_text: 'Kvadratkilometer',
  INQ_text: 'Kubikk tomme',
  MMQ_text: 'Kubikk millimeter',
  FTQ_text: 'Kubikkfot',
  CMQ_text: 'Kubikk centimeter',
  OZA_text: 'Fluid unse',
  PT_text: 'Halvliter',
  QT_text: 'Kvart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Unse',
  LBR_text: 'Pund',
  STN_text: 'Tonn',
  GRM_text: 'Gram',
  KGM_text: 'Kilo',
  TNE_text: 'Tonn',
  PS_text: 'Psi',
  MPA_text: 'Megapascal',
  C56_text: 'Newton per kvadrat millimeter',
  C62_text: 'En',
  NL_text: 'Laste',
  MT_text: 'Matte',
  P1_text: 'Prosent',
  CEL_text: 'Grader celsius',
  FAH_text: 'Grad Fahrenheit',
  BLL_text: 'Tønne',
  CLT_text: 'Centiliter',
  DLT_text: 'Desilitere',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sekk',
  '60_text': 'Prosent vekt',
  BG_text: 'Bag',
  SEC_text: 'Sekund',
  MIN_text: 'Minutt',
  '64_text': 'Pund per kvadrat tomme - måler',
  SP_text: 'Hyllepakke',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    one {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    one {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    one {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    one {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    one {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    one {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    one {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    one {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    one {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    one {1 Percent}
    other {# Percent}
  }`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    one {1 Degree Celsius}
    other {# Degrees Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    one {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    one {1 Barrel}
    other {# Barrels}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    one {1 Centilitre}
    other {# Centilitres}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    one {1 Decilitre}
    other {# Decilitres}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    one {1 Megagram}
    other {# Megagrams}
  }`,
  SA: `{value, plural,
    =0 {0 Sacks}
    one {1 Sack}
    other {# Sacks}
  }`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    one {1 Percent Weight}
    other {# Percent Weight}
  }`,
  BG: `{value, plural,
    =0 {0 Bags}
    one {1 Bag}
    other {# Bags}
  }`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    one {1 Second}
    other {# Seconds}
  }`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    one {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
  }`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    one {1 Shelf Package}
    other {# Shelf Packages}
  }`,
  mixIdLabel: 'Blanding/Beskrivelse',
  orderedQtyLabel: 'Bestilt mengde',
  loadedQtyLabel: 'Lastet mengde',
  addedByCustomer: 'Lagt til av kunde',
  PRINTED: 'Bestilt',
  LOADING_STARTED: 'Begynn å laste',
  LOADING_COMPLETE: 'Fullfør lasting',
  TO_JOB: 'Forlatt fabrikk',
  ARRIVE_JOB: 'Ankomst byggeplass',
  UNLOADING: 'Start lossing',
  END_UNLOADING: 'Fullfør lossing',
  LEAVE_JOB: 'Forlatt arbeid',
  IN_YARD: 'På fabrikkområde',
  PUMPING_STARTED: 'Start pumpe',
  PUMPING_COMPLETE: 'Fullfør pumpen',
  READY_TO_PUMP: 'Klar til å pumpe',
  FIRST_WCCONTACT: 'Første vannsementkontakt',
  plantName: 'Byggeplass navn',
  plantAddress: 'Byggeplassadresse',
  loadingScheduleStart: 'Lasting planlagt startdato og -tid',
  deliveryAddress: 'Leveringsadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Lossing planlagt startdato og -tid',
  agreedUnloading: 'Bestilte lossetider',
  deliveryInformation: 'Leveringsinformasjon',
  next: 'Neste',
  material: 'Materiale',
  loadingPoint: 'Lastested',
  unloadingPoint: 'Lossepunkt',
  comment: 'Kommentar',
  sapDeliveryNumber: 'SAP Leveringsnummer',
  salesOrderNumber: 'Ordrenummer og varelinje',
  wcDateTime: 'Dato og tid W/C kontakt',
  po: 'PO Nummer',
  orderedByName: 'Ordre bestilt av navn',
  orderedByPhone: 'Ordre bestilt av telefonnummer',
  resourceId: 'Ressurs ID',
  totalLoadedQty: 'Totalt lastet mengde inkludert dette lasset',
  spacing: 'Lasteplass',
  deliveryFlow: 'Leveringsflyt',
  timeOnSite: 'Total tid på byggeplass / bestilt lossetid',
  salesText: 'Salgstekst (material-ID)',
  strengthClass: 'Fasthetsklasse',
  environmentExposure: 'Miljøpåvirkning',
  metExposureClasses: 'Nådd eksponeringsklasse',
  dmax: 'Dmaks',
  standardCert: 'Standard (sertifisering)',
  workabilityTarget: 'Kundens gjennomføringsmål',
  stoneQuality: 'Steinkvalitet',
  sandQuality: 'Sandkvalitet',
  targetWC: 'V/C-forholdsmål fra resept',
  controlClass: 'Kontrollklasse',
  chlorideClass: 'Kloridklasse',
  typeAndStrength1: 'Type sement og fasthetsklasse 1',
  typeAndStrength2: 'Type sement og fasthetsklasse 2',
  typeOfAddition: 'Tillegstype',
  typeOfAdmixture: 'Type innblanding',
  orderedTemp: 'Bestilt temperatur',
  fibers1: 'Type og innhold av fiber 1',
  fibers2: 'Type og innhold av fiber 2',
  calculatedMaturityTime: 'Beregnet forfallstid fra V/C kontakttid',
  mixIdentity: 'Bland identitet',
  grade: 'Karakter',
  mixType: 'Blandetype',
  maxAggSize: 'Maks AGG -størrelse',
  maxAggType: 'Maks AGG -type',
  cementType: 'SementType',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix beskrivelse',
  slump: 'Nedgang',
  minCementContent: 'Min sementinnhold',
  maxWatCemRatio: 'Maks vann/sementforhold',
  maxWaterToAdd: 'Maks vann å tilsette',
  mortarMinWorkingLife: 'Mørtel min arbeidsliv',
  code: 'Kode',
  dcClass: 'DCCLASS',
  chloride: 'Klorid',
  signedByHanson: 'Signert av Hanson',
  warmStamp: 'Varmt stempel',
  coldStamp: 'Kaldt stempel',
  containsSikatard: 'Inneholder Sikatard',
  containsDelvo: 'Inneholder Delvo',
  generalDelivery: 'Generell LeveringsInformasjon',
  materialInfo: 'Materialinformasjon',
  water: 'Vann',
  materialService: 'Materiale / service',
  returnedMaterial: 'Returnert material',
  returnedConcrete: 'Returnert betong',
  waterAdded: 'Vann lagt på ',
  concreteOnTruck: 'Betong på bilen',
  concreteOnTruckUom: 'Standard betong på lastebil uom',
  reason: 'Årsak',
  customerAddedWater: 'Kunde lagt til vann',
  addWater: 'Legg til vann',
  addedAt: 'Lagt til på',
  addMaterialService: 'Legg til material/service',
  customerAddedProduct: 'Kunde lagt til produkt',
  addReturnedMaterial: 'Legg til returnert material',
  leftover: 'Overskudd',
  submitAcceptance: 'Send inn aksept',
  accepted: 'Akseptert',
  customerAcceptance: 'Kundeaksept',
  finalize: 'Fullfør',
  materialSummary: 'Materialoppsummering',
  mix: 'Blanding',
  ordered: 'Bestilt',
  loaded: 'Lastet mengde',
  totalOrdered: 'Total ordremengde',
  loadedOrderQuantity: 'Lastet bestillingsmengde',
  loadedOrder: 'Lastet ordre',
  driverComment: 'Sjåførkommentar',
  customerComment: 'Kundekommentar',
  editComment: 'Redigere kommentar',
  addComment: 'Legg til kommentar',
  additionalInfo: 'Tillegsinformasjon',
  onBoard: 'Om bord',
  driver: 'Sjåfør',
  commentInstruction: 'Kommentarinstruksjon',
  signatureInstruction: 'Signaturinstruksjon',
  customerSignature: 'Kundesignatur',
  general: 'Generell',
  status: 'Status',
  updateStatusTime: 'Oppdatere status tid',
  delete: 'Slett',
  cancel: 'Avbryt',
  back: 'Tilbake',
  add: 'Legg til',
  done: 'ferdig',
  ok: 'OK',
  decline: 'Avslå',
  Submit: 'Sende inn',
  submit: 'Sende inn',
  Time: 'Tid',
  clear: 'klar',
  lastRefresh: 'Siste oppdatering',
  refreshEnabled: 'Aktiver oppdatering',
  id: 'ID',
  name: 'Navn',
  invoiceable: 'Fakturerbar',
  provideSignature: 'Gi signatur',
  ticket: 'Følgeseddel',
  details: 'Detaljer',
  qrCode: 'QR',
  driverAcceptanceReason: 'Årsak',
  driverAcceptance: 'Sjåføraksept',
  'payment-method': 'betalingsmetoder',
  'add-payment-method': 'Legg til betalingsmåte',
  'reason-code': 'Årsakskode',
  'add-reason-code': 'Legg til årsakskode',
  'qr-code': 'QR-kode',
  'unloading-method': 'Lossemetoder',
  'add-unloading-method': 'Legg til lossemetoder',
  'driver-comment': 'Sjåførkommentar',
  'add-driver-comment': 'Legg til sjåførkommentar',
  'driver-sellable': 'Sjåførens selges',
  vehicle: 'Kjøretøyer',
  'add-vehicle': 'Legg til kjøretøy',
  'vehicle-type': 'Kjøretøytyper',
  vehicleTypes: 'Kjøretøytyper',
  vehicleType: 'Bil type',
  'add-vehicle-type': 'Legg til kjøretøytype',
  countries: 'Land',
  country: 'Land',
  type: 'Type',
  uom: 'Måleenhet',
  mandatory: 'Obligatorisk',
  qrTemplate: 'QR-kode mal',
  includeMixInfo: 'Inkluder blandingsinformasjon i QR-kode',
  includeTicketEvents: 'Inkluder følgeseddelhendelser i QR-kode',
  quantityField: 'Aktiver mengdefelt',
  concreteOnTruckField: 'Aktiver betong på bilen felt',
  timeField: 'Aktiver tidsfelt',
  reasonField: 'Aktiver årsaksfelt',
  driverInstructions: 'Sjåførinstruksjoner',
  waitingForTicket: 'Venter på billett...',
  createTestTicketForTruck: 'Lag testbillett for lastebil',
  exceedsLoadedQuantity: 'Verdien kan ikke overstige det innlastede antallet på {loadedQuantity}.',
  maxQuantity: 'Maksimal mengde',
  maxQuantityError: 'Mengde kan ikke overstige definert maksimal mengde {Maxqty}',
  maxValueError: 'Verdien kan ikke overstige definert maksimum på {Maxqty}',
  minValueError: 'Verdien kan ikke være mindre enn definert minimum {minqty}',
  notAuthorized: 'Ikke autorisert',
  enterTruckNumber: 'Skriv inn lastebilnummer',
  setTruckNumber: 'Angi lastebilnummer',
  commentList: 'Kommentarer liste',
  list: 'Liste',
  gridPreferences: 'Nettpreferanser',
  loadingUnloadingPoints: 'Lasting/lossingspunkter',
  activity: 'Aktivitet',
  crn: 'Crn',
  update: 'Oppdater',
  CONCRETE: 'Betong',
  WATER: 'Vann',
  ADDITIVE: 'Tilsetningsstoff',
  'submit-signature-button': 'Aksepterer',
  'cancel-signature-button': 'Avbryt',
  uomCode: 'Standard UOM',
  'product-type': 'Produkttyper',
  'add-product-type': 'Legg til produkttype',
  'add-product': 'Legg til produkt',
  'setup_driver-comment': 'Sjåførkommentarer',
  setup_product: 'Produkter',
  'setup_product-type': 'Produkttyper',
  'setup_qr-code': 'QR kode',
  'setup_reason-code': 'Årsakskoder',
  'setup_unloading-method': 'Lossing av metoder',
  'setup_entity-mt': 'Selskap',
  setup_vehicle: 'Kjøretøyer',
  'setup_vehicle-type': 'Kjøretøytyper',
  'setup_data-retentions': 'Dataoppbevaring',
  'add-data-retentions': 'Legg til dataoppbevaring',
  'data-retentions': 'Dataoppbevaring',
  setup_carriers: 'Transportører',
  'add-carriers': 'Legg til transportører',
  carriers: 'Transportører',
  'setup_concrete-classes': 'Materiell etterlevelse',
  'add-concrete-classes': 'Legg til materiell etterlevelse',
  'concrete-classes': 'Materiell etterlevelse',
  'setup_forms-and-waivers': 'Skjemaer og dispensasjoner',
  'add-forms-and-waivers': 'Legg til skjemaer og dispensasjoner',
  'forms-and-waivers': 'Skjemaer og dispensasjoner',
  companyFleet: 'Company Fleet',
  category: 'Kategori',
  interval: 'Intervall',
  orders: 'Bestillinger og billetter',
  '30days': '30 dager',
  '60days': '60 dager',
  '90days': '90 dager',
  '1year': '1 år',
  '3years': '3 år',
  waterMandatory: 'Vann obligatorisk',
  qrCodeTemplate: 'QR -Kodemal',
  waterConfig: 'Valgfrie felt',
  waterProduct: 'Vann',
  waterProductType: 'Vannprodukttype',
  defaultWaterProduct: 'Standard vannprodukt',
  invalidTemplate: 'Ugyldig mal',
  siteAddress: 'Nettstedsadresse',
  siteContactName: 'Nettstedskontaktnavn',
  siteContactPhoneNumber: 'Nettsted Kontakt Telefonnummer',
  'clear-signature-button': 'Klar signatur',
  customerCommentEnabled: 'Aktiver kundekommentar',
  driverCommentEnabled: 'Aktiver sjåførkommentar',
  driverCommentMaxCharacters: 'Maks lengde',
  customerCommentMaxCharacters: 'Maks lengde',
  timeZone: 'Tidssone',
  driverDidNotAdd: 'Sjåføren la ikke til',
  maxLineItems: 'Maks antall linjeartikler',
  oneLineItem: 'Må ha minst 1 linjeelement',
  signatureSiteContact: 'Kundenavn',
  ticketEvents: 'Billettarrangementer',
  hideEvent: 'Skjul arrangement',
  editable: 'Redigerbar',
  ticketDetail: `Billett #{value}`,
  statusTimes: 'Statusider',
  materialAndDeliveryInfo: 'Informasjon og leveringsinformasjon',
  customerRejection: 'Kundeavvisning',
  'submit-rejection-button': 'Sende inn avvisning',
  accept: 'Aksepterer',
  reject: 'Avvise',
  signature: 'Signatur',
  'no-signature': 'Ingen signatur',
  unFinalizePassword: 'Un-finize passord',
  waterInWorkflow: 'Aktiver vanntrinn i arbeidsflyten',
  multicountry: 'Multiland',
  timestampAfterSequence: 'Advarsel: Tid skal ikke være før tidligere tidsstempel',
  timestampBeforeSequence: 'Advarsel: Tid skal ikke være etter neste tidsstempel',
  noRestQuantity: 'Ingen hvilemengde',
  returnedMaterialMandatory: 'Må samhandle med returnert materiale trinn',
  driverCommentMaxChars: 'Lengde kan ikke overstige maks av {maxLength} tegn.',
  QrCodeEnabled: 'Aktiver QR -kode',
  returnedMaterialInWorkflow: 'Aktiver returnert materiale trinn i arbeidsflyten',
  materialServiceInWorkflow: 'Aktiver materiale/servicetrinn i arbeidsflyten',
  totalPumpedQuantity: 'Total pumpet mengde',
  concrete: 'Betong',
  materialComplianceEnabled: 'Aktiver vesentlig etterlevelse',
  pumpStatusTimes: 'Pumpestatustider',
  pollingInterval: 'Pollingintervall (sekunder)',
  tenSecs: 'Intervallet må være minst 10 sekunder',
  materialCompliance: 'Materiell etterlevelse',
  consistenceValue: 'Konsistens',
  consistenceIsCompliant: 'Konsekvens er i samsvar med BS8500-2',
  airContentValue: 'Luftinnhold',
  airContentIsCompliant: 'Luftinnhold er i samsvar med BS8500-2',
  consistenceIsCompliantShort: 'Konstens samsvarer',
  airContentIsCompliantShort: 'Luftinnhold samsvarer',
  compliance: 'Materiell samsvar med BS8500-2',
  termsAndConditionsEnabled: 'Aktiver skjema for vilkår og betingelser',
  termsAndConditions: 'Vilkår og betingelser',
  damageWaiverEnabled: 'Aktiver skader fra dispensasjon',
  damageWaiver: 'Skadefravik',
  dynamicWaiver: 'Dynamisk avkall',
  dynamicWaiverEnabled: 'Aktiver dynamisk avkallsskjema',
  ticketListEnabled: 'Aktiver billettliste',
  clearTicketAfterSubmission: 'Tøm billett etter innsending',
  keepTicketOpenAfterSubmission: 'Hold billett åpen etter innsending',
  displayTicketList: 'Vis billettliste',
  supportedLanguages: 'Støttede språk',
  allow: 'Tillate',
  default: 'Misligholde',
  translations: 'Oversetteller',
  family_name: 'Etternavn',
  given_name: 'Fornavn',
  email: 'E -post',
  role: 'Rolle',
  inviteUser: 'Inviter bruker',
  carrier: 'Transportør',
  emailOrPhoneNumber: 'E -post',
  usersEditor: 'Brukerredaktør',
  verifyPassword: 'Bekreft passord',
  verificationCode: 'Bekreftelseskode',
  register: 'Registrere',
  registerInstructions: 'Vennligst fyll ut dette skjemaet for å fullføre registreringen.',
  'password-requirements':
    'Uw wachtwoord moet minimaal 8 tekens lang zijn en minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken bevatten.',
  passwordMinCharacters: 'Passordet må inneholde 8 eller flere tegn.',
  passwordMaxCharacters: 'Passord kan ikke inneholde mer enn 99 tegn.',
  passwordLowerCase: 'Passordet må inneholde 1 eller flere små bokstaver.',
  passwordUpperCase: 'Passordet må inneholde 1 eller flere store bokstaver.',
  passwordNumber: 'Passordet må inneholde 1 eller mer numerisk tegn.',
  passwordSpecialCharacter: 'Passordet må inneholde 1 eller mer spesiell tegn.',
  passwordsDontMatch: 'Wachtwoorden komen niet overeen!',
  success: 'Suksess!',
  'registration-success': 'Uw registratie is gelukt.',
  'post-registration-instructions': 'U heeft het registratieproces voltooid. U kunt nu inloggen.',
  createDate: 'lag dato',
  customerPo: 'Kunden PO -nummer',
  specification: 'Spesifikasjon',
  description: 'Beskrivelse',
  classType: 'Klassetype',
  minimumValue: 'Minimumsverdi',
  maximumValue: 'Maksimal verdi',
  TrackIt: 'Spor det',
  trackIt3PContext: 'Trackit 3P -kontekst',
  inviteLanguage: 'Inviter språk',
  truckNumber: 'Lastebilnummer',
  adverseTemperature:
    'De omgevingstemperatuur op het moment van mengen was zodanig dat we niet kunnen garanderen dat het beton zich in het temperatuurbereik van 5-35oC zal bevinden, zoals gespecificeerd in EN 206.',
  environmentalNote:
    'Deze belasting bevat een milieuagent die niet meer dan 0,03% van het gewicht van het beton bedraagt. Water dat dit materiaal bevat voor de vervaardiging van beton voldoet aan BS EN 1008.',
  unloadingMethodEnabled: 'Aktiver lossemetode',
  warrantyVoided: 'Materialgaranti er ugyldig',
  isCertificationLossTriggerForReason: 'Forårsaker sertifiseringstap',
  isCertificationLossTriggerForProduct: 'Forårsaker sertifiseringstap',
  materialWarrantyVoidedEnabled:
    'Når produktet for sertifiseringstap eller produkt & årsakskode brukes, vis en notat om sertifiseringstap ved tidspunktet for signering.',
  'setup_templates-mt': 'Maler',
  copyFrom: 'Kopier fra...',
  Copy: 'Kopieren',
  users: 'Users',
  setup: 'Setup',
  driverExperience: 'Driver Experience',
  ticketList: 'Ticket List',
  content: 'Innhold',
  WARNINGS: 'Advarsler',
  TERMS: 'Vilkår',
  DAMAGE: 'Skader',
  ADDITIONALWAIVER: 'Ekstra avkall',
  DYNAMICWAIVER: 'Dynamisk avkall',
  source: 'Kilde',
  sensorUnable: 'Sensor kan ikke bestemme resterende mengde.',
  PROBE: 'Sonde',
  DRIVER: 'Sjåfør',
  DRS: 'Drs',
  waterReleaseFormEnabled: 'Aktiver skjema for vannfrigjøring',
  waterRelease: 'Vannfrigjøring',
  'assessment-risk': 'Risikovurdering',
  'setup_assessment-risk': 'Risikovurdering',
  'add-assessment-risk': 'Legg til risikovurdering',
  subcategory: 'Underkategori',
  isRequired: 'Er påkrevd',
  riskAssessmentEnabled: 'Aktiver risikovurdering',
  riskAssessment: 'Risikovurdering',
  yes: 'Ja',
  no: 'Nei',
  sourceOptionsSensors: 'Kildealternativer (sensorer)',
  sourceDcm: 'Dcm',
  sourceProbe: 'Sonde',
  availableCustomValues: 'Tilgjengelige tilpassede verdier',
  ERR_CODE__DUPLICATE: 'Duplisere',
  ERR_CODE__DEFAULT_VALUE: 'Det er allerede en standardverdi',
  ERR_CODE__EXISTING_FORM: 'Det er allerede et aktivt skjema for dette kjøretøyet og typen',
  availableValues: 'Tilgjengelige verdier',
  DanskName: 'Dansk navn',
  GermanName: 'Tysk navn',
  EnglishName: 'Engelsk navn',
  BritainName: 'Storbritannia navn',
  SpanishName: 'Spansk navn',
  FrenchName: 'Fransk navn',
  NederlandsName: 'Nederlands navn',
  NorskName: 'Norsk navn',
  SvenskaName: 'Svenska navn',
  ItalianName: 'Italiensk navn',
  PolishName: 'Polsk navn',
  DanskNameDefault: 'Dansk -navn (standard)',
  GermanNameDefault: 'Tysk navn (standard)',
  EnglishNameDefault: 'Engelsk navn (standard)',
  BritainNameDefault: 'Storbritannias navn (standard)',
  SpanishNameDefault: 'Spansk navn (standard)',
  FrenchNameDefault: 'Fransk navn (standard)',
  NederlandsNameDefault: 'Nederlands navn (standard)',
  NorskNameDefault: 'Norsk Name (standard)',
  SvenskaNameDefault: 'Svenska navn (standard)',
  ItalianNameDefault: 'Italiensk navn (standard)',
  PolishNameDefault: 'Polsk navn (standard)',
  DanskDescription: 'Dansk beskrivelse',
  GermanDescription: 'Tysk beskrivelse',
  EnglishDescription: 'Engelsk beskrivelse',
  BritainDescription: 'Storbritannias beskrivelse',
  SpanishDescription: 'Spansk beskrivelse',
  FrenchDescription: 'Fransk beskrivelse',
  NederlandsDescription: 'Nederlands beskrivelse',
  NorskDescription: 'Norsk Beskrivelse',
  SvenskaDescription: 'Svenska beskrivelse',
  ItalianDescription: 'Italiensk beskrivelse',
  PolishDescription: 'Polsk beskrivelse',
  DanskDescriptionDefault: 'Dansk beskrivelse (standard)',
  GermanDescriptionDefault: 'Tysk beskrivelse (standard)',
  EnglishDescriptionDefault: 'Engelsk beskrivelse (standard)',
  BritainDescriptionDefault: 'Storbritannias beskrivelse (standard)',
  SpanishDescriptionDefault: 'Spansk beskrivelse (standard)',
  FrenchDescriptionDefault: 'Fransk beskrivelse (standard)',
  NederlandsDescriptionDefault: 'Nederlands beskrivelse (standard)',
  NorskDescriptionDefault: 'Norsk beskrivelse (standard)',
  SvenskaDescriptionDefault: 'Svenska beskrivelse (standard)',
  ItalianDescriptionDefault: 'Italiensk beskrivelse (standard)',
  PolishDescriptionDefault: 'Polsk beskrivelse (standard)',
  DanskContent: 'Dansk -innhold',
  GermanContent: 'Tysk innhold',
  EnglishContent: 'Engelsk innhold',
  BritainContent: 'Storbritannia innhold',
  SpanishContent: 'Spansk innhold',
  FrenchContent: 'Fransk innhold',
  NederlandsContent: 'Nederlands innhold',
  NorskContent: 'Norsk -innhold',
  SvenskaContent: 'Svenska -innhold',
  ItalianContent: 'Italiensk -innhold',
  PolishContent: 'Polsk innhold',
  DanskContentDefault: 'Dansk Content (standard)',
  GermanContentDefault: 'Tysk innhold (standard)',
  EnglishContentDefault: 'Engelsk innhold (standard)',
  BritainContentDefault: 'Storbritannias innhold (standard)',
  SpanishContentDefault: 'Spansk innhold (standard)',
  FrenchContentDefault: 'Fransk innhold (standard)',
  NederlandsContentDefault: 'Nederlands Content (standard)',
  NorskContentDefault: 'Norsk Content (standard)',
  SvenskaContentDefault: 'Svenska -innhold (standard)',
  ItalianContentDefault: 'Italiensk -innhold (standard)',
  PolishContentDefault: 'Polsk innhold (standard)',
  DanskCategory: 'Dansk -kategori',
  GermanCategory: 'Tysk kategori',
  EnglishCategory: 'Engelsk kategori',
  BritainCategory: 'Storbritannias kategori',
  SpanishCategory: 'Spansk kategori',
  FrenchCategory: 'Fransk kategori',
  NederlandsCategory: 'Nederlands kategori',
  NorskCategory: 'Norsk -kategori',
  SvenskaCategory: 'Svenska kategori',
  ItalianCategory: 'Italiensk kategori',
  PolishCategory: 'Polsk kategori',
  DanskCategoryDefault: 'Dansk -kategori (standard)',
  GermanCategoryDefault: 'Tysk kategori (standard)',
  EnglishCategoryDefault: 'Engelsk kategori (standard)',
  BritainCategoryDefault: 'Storbritannias kategori (standard)',
  SpanishCategoryDefault: 'Spansk kategori (standard)',
  FrenchCategoryDefault: 'Fransk kategori (standard)',
  NederlandsCategoryDefault: 'Nederlands kategori (standard)',
  NorskCategoryDefault: 'Norsk kategori (standard)',
  SvenskaCategoryDefault: 'Svenska kategori (standard)',
  ItalianCategoryDefault: 'Italiensk kategori (standard)',
  PolishCategoryDefault: 'Polsk kategori (standard)',
  DanskSubcategory: 'Dansk underkategori',
  GermanSubcategory: 'Tysk underkategori',
  EnglishSubcategory: 'Engelsk underkategori',
  BritainSubcategory: 'Storbritannias underkategori',
  SpanishSubcategory: 'Spansk underkategori',
  FrenchSubcategory: 'Fransk underkategori',
  NederlandsSubcategory: 'Nederlands underkategori',
  NorskSubcategory: 'Norsk Subkategori',
  SvenskaSubcategory: 'Svenska underkategori',
  ItalianSubcategory: 'Italiensk underkategori',
  PolishSubcategory: 'Polsk underkategori',
  DanskSubcategoryDefault: 'Dansk underkategori (standard)',
  GermanSubcategoryDefault: 'Tysk underkategori (standard)',
  EnglishSubcategoryDefault: 'Engelsk underkategori (standard)',
  BritainSubcategoryDefault: 'Storbritannias underkategori (standard)',
  SpanishSubcategoryDefault: 'Spansk underkategori (standard)',
  FrenchSubcategoryDefault: 'Fransk underkategori (standard)',
  NederlandsSubcategoryDefault: 'Nederlands underkategori (standard)',
  NorskSubcategoryDefault: 'Norsk Subkategori (standard)',
  SvenskaSubcategoryDefault: 'Svenska underkategori (standard)',
  ItalianSubcategoryDefault: 'Italiensk underkategori (standard)',
  PolishSubcategoryDefault: 'Polsk underkategori (standard)',
  priorSignatures: 'Tidligere signaturer',
  damageWaiverSignature: 'Skadefraskrivelsessignatur',
  waterReleaseSignature: 'Vannfrigjøringssignatur',
  errors,
  themes,
};
