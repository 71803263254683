import { Form, Responsive } from 'connex-cds';
import React from 'react';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';

export const Header = ({ flow, signed, entitySupportedLanguages }) => {
  const { values } = Form.useFormContext();

  const submitAcceptanceVisible = React.useMemo(
    () => values?.finalized && values?.signature?.image,

    [values?.finalized, values?.signature?.image]
  );

  return (
    <Responsive>
      <HeaderMobile
        flow={flow}
        submitAcceptanceVisible={submitAcceptanceVisible}
        signed={signed}
        entitySupportedLanguages={entitySupportedLanguages}
      />
      <HeaderDesktop
        flow={flow}
        submitAcceptanceVisible={submitAcceptanceVisible}
        entitySupportedLanguages={entitySupportedLanguages}
      />
    </Responsive>
  );
};
