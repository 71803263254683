import { Form, Localization } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-returnedMaterial';

export const ReturnedMaterial = ({ leftOverConcreteUom }) => {
  const { values } = Form.useFormContext();

  if (!values?.returnReason && !values?.driverDidNotAdd) {
    return null;
  }

  return (
    <Styled className={cn('status-times-mobile')}>
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            <Localization.Translate stringId="returnedConcrete" data-testid="quantity-label" />
          </td>
        </tr>
        <tr>
          <td className="header-cell" data-testid={`${testId}-activity-value`}>
            <Localization.Translate stringId="quantity" data-testid="quantity-label" />
          </td>
          <td data-testid={`${testId}-concrete-value`}>
            <Localization.Uom
              uom={
                values?.returnConcreteOnTruck?.value
                  ? values.returnConcreteOnTruck
                  : { value: '0', uomCode: leftOverConcreteUom }
              }
            />
          </td>
        </tr>

        <tr>
          <td className="header-cell">
            <Localization.Translate stringId="reason" data-testid="reason-label" />
          </td>
          <td data-testid={`${testId}-reason-value`}>
            {(values?.returnConcreteOnTruck?.value && values?.returnReason?.description) || ''}
          </td>
        </tr>
      </table>
    </Styled>
  );
};
