import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useTicketContext } from '../../../../TicketContext';
import style from './style';
import { Core } from 'connex-cds';

import { Localization, Typography } from 'connex-cds';
import { useCompanySetup, useFormsAndWaiversSetup, useVehicleSetup } from '../../../../MasterDataProvider';
import { activeFormsAndWaiversArray } from '../../../../getActiveForms';

const { Translate } = Localization;
const { Subtitle } = Typography;

const Styled = styled.div`
  ${style}
`;

export const WaterRelease = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'WATER',
        formsAndWaiversSetup,
        vehicleSetup,
        companySetup,
        translateObjects,
      }),
    [companySetup, formsAndWaiversSetup, ticket, vehicleSetup, translateObjects]
  );

  return (
    <Styled className={cn('water-release')}>
      <Core.Spinner spin={vehicleSetup.isLoading || formsAndWaiversSetup.isLoading || companySetup.isLoading}>
        <Subtitle>
          <Translate stringId="waterRelease" data-testid="water-release" />
        </Subtitle>
        <div>
          <div data-testid="waterRelease">
            <pre className={'water-release-value'}>{activeFormsAndWaivers[0]?.content}</pre>
          </div>
        </div>
      </Core.Spinner>
    </Styled>
  );
};
