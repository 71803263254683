import cn from 'classnames';
import { Drawer, Form, Layout, Localization } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup, useProductSetup, useReasonCodeSetup, useVehicleSetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import style from './form-style';

const Styled = styled.div`
  ${style}
`;

const { Row, Container } = Layout;

export const FormSection = ({ className }) => {
  const { translateObjects } = Localization.useTranslateObjects();
  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();

  const {
    Components: { Reason, Product, Quantity, ConcreteOnTruck, Time, CancelButton, SaveButton, DriverDidNotAdd },
    values,
    setFieldValue,
    setStatus,
    mode,
  } = Form.useFormContext();

  const { closeDrawer } = Drawer.useDrawerContext();

  React.useEffect(() => {
    if (!values?.item || values?.item?.mobileTicket?.isReasonFieldEnabled === false) {
      setFieldValue('reason', undefined);
    }
    if (!values?.item || values?.item?.mobileTicket?.isConcreteOnTruckFieldEnabled === false) {
      setFieldValue('concreteOnTruck', undefined);
    }
    //TODO: We probably want to change the way we do the validation to allow for this kind of on the fly validation
    // changes have been made...this can move to standard validation.
    if (
      values?.item?.mobileTicket?.maxQuantity &&
      Number(values?.quantity?.value) > Number(values?.item?.mobileTicket?.maxQuantity)
    ) {
      setStatus({ quantity: { key: 'maxQuantityError', values: { maxQty: values?.item?.mobileTicket?.maxQuantity } } });
    } else {
      setStatus();
    }
  }, [setFieldValue, setStatus, values?.item, values?.quantity?.value]);

  const { ticket } = useTicketContext();
  const vehicleSetup = useVehicleSetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const productSetup = useProductSetup();
  const companySetup = useCompanySetup();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [vehicleSetup.data, ticket?.vehicleId]);

  const options = React.useMemo(() => {
    if (!reasonCodeSetup?.data) {
      return [];
    }

    const reasons = reasonCodeSetup?.data
      ?.filter?.(d => d?.type === 'ADDITIONAL_ITEM')
      ?.filter?.(reason => reason?.status === 'ACTIVE')
      ?.filter?.(reason =>
        companySetup?.data?.isMulticountry === true
          ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
          : reason
      )
      ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length);

    const reasonCodes = translateObjects(reasons, {
      getStringId: x => `SETUP_REASON-CODE_${x}`.replace(/\s/gi, '-').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'description',
    });

    if (!reasonCodes) {
      return reasons;
    }

    return reasonCodes.map(reason => ({ ...reason, description: reason?.label ?? reason?.description }));
  }, [
    companySetup?.data?.isMulticountry,
    reasonCodeSetup.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    translateObjects,
  ]);

  const productOptions = React.useMemo(() => {
    if (!productSetup?.data) {
      return [];
    }

    const products = productSetup?.data
      ?.filter?.(p => p.isDriverSellable)
      ?.filter?.(product => product.status === 'ACTIVE')
      ?.filter?.(product =>
        companySetup?.data?.isMulticountry === true
          ? product?.countries?.includes(ticket?.origin?.address?.countryCode) || !product?.countries?.length
          : product
      )
      ?.filter?.(product => product?.vehicleTypes?.includes(vehicleType) || !product?.vehicleTypes?.length);

    const productsTranslated = translateObjects(products, {
      getStringId: x => `SETUP_PRODUCT_${x}`.replace(/\s/gi, '-').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'name',
    });

    if (!productsTranslated) {
      return products;
    }

    return productsTranslated.map(product => ({
      ...product,
      name: product?.label ?? product?.name ?? product?.description,
    }));
  }, [
    companySetup?.data?.isMulticountry,
    productSetup.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    translateObjects,
  ]);

  React.useEffect(() => {
    const defaultConcreteOnTruckUomId = companySetup.data?.concreteOnTruckUom;
    setConcreteOnTruckUom(defaultConcreteOnTruckUomId);
  }, [companySetup.data]);

  const mobileTicketConfig = React.useMemo(() => {
    const targetItem = find(productSetup.data, { crn: values?.item?.productRef });
    return targetItem?.mobileTicket;
  }, [productSetup.data, values?.item?.productRef]);

  const productUom = React.useMemo(() => {
    const targetProduct = find(productSetup.data, { crn: values?.item?.productRef });
    return targetProduct?.uomCode;
  }, [productSetup.data, values?.item?.productRef]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);

  const productDisabled = React.useMemo(() => {
    return values?.requiresDriverReview || values?.suggestedToDriver || values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd, values?.requiresDriverReview, values?.suggestedToDriver]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      const idx = ticket?.lineItems.findIndex(lineItem => lineItem?.item?.productRef === values?.item?.productRef);
      setFieldValue('quantity', ticket?.lineItems[idx]?.quantity);
      setFieldValue('reason', undefined);
      setFieldValue('concreteOnTruck', undefined);
    }
  }, [setFieldValue, ticket?.lineItems, values?.driverDidNotAdd, values?.item?.productRef]);

  React.useEffect(() => {
    if (values?.item?.mobileTicket?.isReasonFieldEnabled && !values?.reason && options?.length === 1) {
      const [firstOption] = options;
      const { crn, ...firstOptionProps } = firstOption;
      setFieldValue('reason', { ...firstOptionProps, reasonRef: crn });
    }
  }, [setFieldValue, options, values?.item, values?.reason]);

  const defaultReason = React.useMemo(() => {
    if (options.some(opt => opt.isDefault)) {
      return options.filter(opt => opt.isDefault);
    }
    return null;
  }, [options]);

  React.useEffect(() => {
    if (defaultReason && !values?.reason) {
      const [option] = defaultReason;
      const { crn, ...defaultReasonProps } = option;
      setFieldValue('reason', { ...defaultReasonProps, reasonRef: crn });
    }
  }, [defaultReason, setFieldValue, values?.reason]);

  return (
    <Styled className={cn('form-section add-products-form-section', className)}>
      <Container flex={1}>
        <div className="form">
          <div className="row-1">
            <Product
              options={productOptions}
              busy={productSetup.isLoading}
              showSearch={false}
              disabled={productDisabled}
            />
            <Quantity uomCode={productUom} disabled={disabled} />
          </div>
          <div className="row-2">
            <div className="left">
              {mobileTicketConfig?.isReasonFieldEnabled && (
                <Reason options={options} busy={reasonCodeSetup.isLoading} showSearch={false} disabled={disabled} />
              )}
              {mobileTicketConfig?.isConcreteOnTruckFieldEnabled && (
                <ConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
              )}
            </div>
            {mobileTicketConfig?.isTimeFieldEnabled && <Time disabled={disabled} />}
          </div>
          {mode === 'edit' && (
            <div className="row-3">
              <DriverDidNotAdd />
            </div>
          )}
        </div>
        <div className="actions">
          <div className="right">
            <Row>
              <CancelButton onCancel={closeDrawer} />
              <SaveButton onDone={closeDrawer} />
            </Row>
          </div>
        </div>
      </Container>
    </Styled>
  );
};
