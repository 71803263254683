import { Form, Localization } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-productSvc';

export const ProductsServices = () => {
  const { values } = Form.useFormContext();

  const products = React.useMemo(() => {
    return values.lineItems.filter(lineItem => !lineItem.isPrimary && !lineItem.driverDidNotAdd);
  }, [values.lineItems]);

  if (!values?.lineItems?.length) {
    return null;
  }

  return (
    <Styled className={cn('status-times-mobile')}>
      {products?.map?.((materialService, index) => {
        return (
          <table>
            <tr className="header-row">
              <td className="header-cell full" colSpan="2" data-testid={`${testId}-row-${index}-activity-value`}>
                {materialService?.item?.name ?? materialService?.item?.description ?? ''}
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="quantity" data-testid="quantity-label" />
              </td>
              <td data-testid={`${testId}-row-${index}-quantity-value`}>
                <Localization.Uom uom={materialService.quantity} />
              </td>
            </tr>
            <tr>
              <td className="header-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Translate stringId="concreteOnTruck" data-testid="water-added-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-concrete-value`}>
                <Localization.Uom uom={materialService.concreteOnTruck} />
              </td>
            </tr>
            <tr>
              <td className="header-cell">
                <Localization.Translate stringId="reason" data-testid="reason-label" />
              </td>
              <td className="data-cell" data-testid={`${testId}-row-${index}-reason-value`}>
                {materialService?.reason?.description ?? ''}
              </td>
            </tr>
          </table>
        );
      })}
    </Styled>
  );
};
