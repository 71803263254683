import enUS from './en-US';
import de from './de';
import esCo from './es-CO';
import enGB from './en-GB';
import fr from './fr-FR';
import nl from './nl-NL';
import sv from './sv-SE';
import da from './da';
import no from './no-NO';
import itIT from './it-IT';
import plPL from './pl-PL';
import pl from './pl';

export default {
  'en-US': enUS,
  'en-GB': enGB,
  de,
  'es-CO': esCo,
  'fr-FR': fr,
  'nl-NL': nl,
  'sv-SE': sv,
  da,
  pl,
  'no-NO': no,
  'it-IT': itIT,
  'pl-PL': plPL,
};
