import { Form } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-damageWaiverAck';

export const DamageWaiverAck = () => {
  const { values } = Form.useFormContext();

  if (values.damageWaiverDeclined || !values.damageWaiverSignature?.image) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>Damage Waiver Acknowledged</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};
