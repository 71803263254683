import { Form } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-damageWaiverAck';

export const DamageWaiverAck = () => {
  const { values } = Form.useFormContext();

  if (values.damageWaiverDeclined || !values.damageWaiverSignature?.image) {
    return null;
  }

  return (
    <Styled className={cn('damage-waiver-mobile')}>
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            Damage Waiver Acknowledged
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </Styled>
  );
};
