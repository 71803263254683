import { http } from 'connex-cds';

export default {
  listTickets: ({ queryKey }) => {
    const [, entityRef, vehicleId] = queryKey;
    return http.get({
      apiName: 'dispatch',
      path: `/${entityRef}/tickets`,
      queryParams: { vehicleId, dateOption: 'Today' },
    });
  },
};
