import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import React from 'react';
import { find } from 'lodash';
import messages from '../../../../../../../i18n';
import { AcceptanceMobile } from './AcceptanceMobile';
import { AcceptanceDesktop } from './AcceptanceDesktop';
import { useCompanySetup, useFormsAndWaiversSetup, useVehicleSetup } from '../../../MasterDataProvider';
import { activeFormsAndWaiversArray } from '../../../getActiveForms';
import { useTicketContext } from '../../../TicketContext';
import { languagesNames } from '../../../setup/languagesNames';

export const FLOW = {
  ACCEPT_REJECT: 'accept-reject',
  SIGNATURE_NO_SIGNATURE: 'signature-no-signature',
};

export const Acceptance = ({ handleBackClick }) => {
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const { ticket } = useTicketContext();
  const { userLocale, timeZone } = Localization.useLocalizationContext();
  const { translateObjects } = Localization.useTranslateObjects();

  const entitySupportedLanguages = React.useMemo(() => {
    if (!companySetup?.data) {
      return [];
    }

    return Object.keys(companySetup?.data)
      .filter(key => key?.includes('allow') && key?.includes('Language') && companySetup?.data[key])
      .map(key => {
        const variableName = key?.replace(/(allow)|Language$/gi, '');
        const languages = find(languagesNames, { variableName });
        return languages?.locale;
      });
  }, [companySetup?.data, userLocale]);

  // TODO: This will come from company config.
  const flow = FLOW.SIGNATURE_NO_SIGNATURE;

  const signed = React.useMemo(() => {
    return !!values?.signature?.image;
  }, [values?.signature?.image]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'DYNAMICWAIVER',
        formsAndWaiversSetup,
        vehicleSetup,
        companySetup,
        translateObjects,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, companySetup, translateObjects]
  );

  return (
    <Localization.Localization messages={messages} initialLocale={userLocale} initialTimeZone={timeZone}>
      <Drawer.DrawerProvider>
        <Responsive>
          <AcceptanceMobile
            handleBackClick={handleBackClick}
            signed={signed}
            flow={flow}
            activeFormsAndWaivers={activeFormsAndWaivers}
            entitySupportedLanguages={entitySupportedLanguages}
          />
          <AcceptanceDesktop
            handleBackClick={handleBackClick}
            signed={signed}
            flow={flow}
            activeFormsAndWaivers={activeFormsAndWaivers}
            entitySupportedLanguages={entitySupportedLanguages}
          />
        </Responsive>
      </Drawer.DrawerProvider>
    </Localization.Localization>
  );
};
