import { Form, Localization } from 'connex-cds';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';
import { MaterialComplianceEditor } from '../custom-editors/material-compliance-editor/MaterialComplianceEditor';
import { labelStringIdBullder, languagesNames, pascalCase } from '../languagesNames';

const { FIELD_TYPES } = Form;

const buildField = (sourceField, companySetup) => language => {
  const { variableName, locale } = language;
  const languageDefault = companySetup?.data[`default${variableName}Language`];
  const labelString = languageDefault ? 'Default' : '';
  return {
    path: `i18n.${sourceField}.${locale}`,
    labelStringId: `${variableName}${pascalCase(sourceField)}${labelString}`,
    testId: `${sourceField}_${variableName}`,
    dataType: FIELD_TYPES.string,
    hidden: true,
  };
};

const buildLanguageFields = companySetup => {
  if (!languagesNames) {
    return [];
  }
  const languagesFiltered = languagesNames?.filter(language => {
    if (!companySetup?.data) {
      return false;
    }

    const { variableName } = language;
    return companySetup?.data[`allow${variableName}Language`];
  });

  return [
    ...languagesFiltered?.map(buildField('name', companySetup)),
    ...languagesFiltered?.map(buildField('description', companySetup)),
  ];
};

const fields = companySetup => ({
  icon: <i className="icon fa-thin fa-layer-group" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    classType: Yup.string().required('required'),
    minimumValue: Yup.string().required('required'),
    maximumValue: Yup.string().required('required'),
    uomCode: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  customEditor: MaterialComplianceEditor,
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    {
      path: 'name',
      labelStringId: 'name',
      testId: 'name',
      dataType: FIELD_TYPES.string,
    },
    {
      path: 'description',
      labelStringId: 'description',
      testId: 'description',
      dataType: FIELD_TYPES.string,
    },
    {
      path: 'classType',
      labelStringId: 'classType',
      testId: 'classType',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'air', label: 'Air' },
        { id: 'consistence', label: 'Consistence' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="air">Air</option>
            <option value="consistence">Consistence</option>
          </select>
        );
      },
    },
    { path: 'minimumValue', labelStringId: 'minimumValue', testId: 'minimumValue', dataType: FIELD_TYPES.string },
    { path: 'maximumValue', labelStringId: 'maximumValue', testId: 'maximumValue', dataType: FIELD_TYPES.string },
    {
      path: 'uomCode',
      labelStringId: 'uom',
      testId: 'uomCode',
      listValuePath: 'id',
      listDisplayPath: 'description',
      dataType: FIELD_TYPES.string,
      listTypeId: 'uom',
      listValues: [],
      formatter: props => (
        <div data-testclass={'cell-column-uomCode'} data-testid={`row-${props.row.crn}-column-uomCode`}>
          <Localization.Translate stringId={`${props.row.uomCode}_text`} data-testid="" />
        </div>
      ),
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
    ...buildLanguageFields(companySetup),
  ],
});

export default fields;
