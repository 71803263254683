import { Form } from 'connex-cds';
import React from 'react';

const testId = 'activityGrid-waterRelease';

export const WaterRelease = () => {
  const { values } = Form.useFormContext();

  if (
    !values?.waterAddedEvents.filter(
      waterAddedEvent => waterAddedEvent.waterReleaseDeclined || waterAddedEvent.waterReleaseSignature?.image
    )?.length
  ) {
    return null;
  }

  return (
    <tr>
      <td data-testid={`${testId}-activity-value`}>Water Release Acknowledged</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};
