const constants = {
  ESCAPED_COMMA_PLACEHOLDER: '::__COMMA__::',
  EMPTY: '::__EMPTY__::',
  PRIMARY_LINE_ITEM: 'primaryLineItem',
  OR: 'or::',
  IF: 'if::',
  GT: 'gt::',
  UOM: 'uom::',
  DATE_TIME: 'dateTime::',
};

export default constants;
