import { Form } from 'connex-cds';
import React from 'react';
import cn from 'classnames';
import { Styled } from '../../../../../ticket-details/logistical-info/StatusTimesMobile';

const testId = 'activityGrid-waterRelease';

export const WaterRelease = () => {
  const { values } = Form.useFormContext();

  if (
    !values?.waterAddedEvents.filter(
      waterAddedEvent => waterAddedEvent.waterReleaseDeclined || waterAddedEvent.waterReleaseSignature?.image
    )?.length
  ) {
    return null;
  }

  return (
    <Styled className={cn('water-release-mobile')}>
      <table>
        <tr className="header-row">
          <td className="header-cell" data-testid={`${testId}-activity-value`} colSpan="2">
            Water Release Acknowledged
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </Styled>
  );
};
