import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GenericErrorBoundary } from './GenericErrorBoundary';
import { cacheItem } from './util/cache';

try {
  const queryParams = queryString.parse(window.location.search);
  if (!isEmpty(queryParams)) {
    const previousConnexId = localStorage.getItem('x-connex-id') || '';

    const connexId = queryParams['x-connex-id'] || previousConnexId;
    const source = queryParams['source'] || localStorage.getItem('source');
    const language = queryParams['language'] || localStorage.getItem('language');
    const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'dark';
    const entityRef = queryParams['entityRef'] || localStorage.getItem('entityRef');
    const ticketRef = queryParams['crn'];

    cacheItem('source', source || '');
    cacheItem('themeName', themeName);
    cacheItem('language', language || '');
    cacheItem('entityRef', entityRef);
    cacheItem('ticketRef', ticketRef);

    if (connexId) {
      cacheItem('x-connex-id', connexId);
    }

    // Strip query params off URL
    if (window?.history?.replaceState) {
      window.history.replaceState(
        {},
        null,
        `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`
      );
    }
  }
} catch (e) {
  // eslint-disable-next-line no-restricted-globals
  parent.postMessage(JSON.stringify({ type: 'debug', message: `index.js: ${e.message}` }), '*');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GenericErrorBoundary>
    <App />
  </GenericErrorBoundary>
);
