import cn from 'classnames';
import { Core, Drawer, Form, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useCompanySetup, useCreateItem, useMaterialComplianceSetup, useUpdateItem } from '../../../MasterDataProvider';
import style from './style';
import { languagesNames, pascalCase } from '../../languagesNames';

const Styled = styled.div`
  ${style}
`;

export const MaterialComplianceEditor = ({ config }) => {
  const { Components, values, mode, setFieldValue } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();

  const materialComplianceSetup = useMaterialComplianceSetup();

  const busy = React.useMemo(() => materialComplianceSetup.isLoading, [materialComplianceSetup.isLoading]);

  const create = useCreateItem();
  const update = useUpdateItem();

  const companySetup = useCompanySetup();
  const { userLocale } = Localization.useLocalizationContext();

  const currentUserLocale = React.useMemo(() => {
    const [defaultLanguage] = Object.keys(companySetup?.data)
      .filter(key => key?.includes('default') && key?.includes('Language') && companySetup?.data[key])
      .map(key => {
        const variableName = key?.replace(/(default)|Language$/gi, '');
        const languagesName = find(languagesNames, { variableName });
        return languagesName?.locale;
      });
    return defaultLanguage;
  }, [companySetup?.data, userLocale]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue(`i18n.name.${currentUserLocale}`, values?.name);
    }
  }, [setFieldValue, currentUserLocale, values?.name]);

  React.useEffect(() => {
    if (currentUserLocale) {
      setFieldValue(`i18n.description.${currentUserLocale}`, values?.description);
    }
  }, [setFieldValue, currentUserLocale, values?.description]);

  React.useEffect(() => {
    if (currentUserLocale && values?.i18n?.name) {
      setFieldValue('name', values?.i18n?.name[`${currentUserLocale}`]);
    }
  }, [setFieldValue, currentUserLocale]);

  React.useEffect(() => {
    if (currentUserLocale && values?.i18n?.description) {
      setFieldValue('description', values?.i18n?.description[`${currentUserLocale}`]);
    }
  }, [setFieldValue, currentUserLocale]);

  const editSetupData = React.useCallback(
    async item => {
      // return update({ ...item, minimumValue: Number(item?.minimumValue), maximumValue: Number(item?.maximumValue) });
      return update(item);
    },
    [update]
  );

  const saveSetupData = React.useCallback(
    async item => {
      // return create({ ...item, minimumValue: Number(item?.minimumValue), maximumValue: Number(item?.maximumValue) });
      return create(item);
    },
    [create]
  );

  const handleSave = React.useCallback(() => {
    return saveSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, saveSetupData, values]);

  const handleEdit = React.useCallback(() => {
    return editSetupData(values).then(response => {
      closeDrawer();
      return response;
    });
  }, [closeDrawer, editSetupData, values]);

  const allowedLanguages = React.useMemo(() => {
    return (
      Object.keys(companySetup?.data)
        ?.filter(key => {
          const allowedLanguage = new RegExp(/^(allow)\w*Language$/i);
          return allowedLanguage.test(key);
        })
        ?.map(key => companySetup?.data[key])
        ?.filter(language => Boolean(language)).length > 1
    );
  }, [companySetup?.data]);

  const fieldsComponents = React.useMemo(() => {
    const accumulator = { general: [], mobileTicket: [], waterConfig: [] };
    if (allowedLanguages) {
      accumulator['translations'] = [];
    }

    return config?.fields?.reduce((acc, field) => {
      try {
        const FieldComponent = Components[pascalCase(field.name ?? field.path)];
        FieldComponent.displayName = field.name ?? field.path;

        if (field?.path === 'id') {
          acc.general.push(<FieldComponent disabled={mode === 'edit'} />);
          return acc;
        }

        const isDescriptionField = field.path.toLowerCase().includes('i18n');
        if (allowedLanguages && isDescriptionField) {
          acc.translations.push(<FieldComponent disabled={field.path.includes(currentUserLocale)} />);
          return acc;
        }

        if (!allowedLanguages && isDescriptionField) {
          return acc;
        }

        acc.general.push(<FieldComponent />);

        return acc;
      } catch (error) {
        return acc;
      }
    }, accumulator);
  }, [config?.fields, allowedLanguages, currentUserLocale]);

  const tabsConfig = React.useMemo(() => {
    const tabs = [
      {
        stringId: 'general',
        testId: 'general-tab',
        component: fieldsComponents?.general,
      },
    ];

    if (allowedLanguages) {
      tabs.push({
        stringId: 'translations',
        testId: 'translations-tab',
        component: fieldsComponents?.translations,
      });
    }

    return {
      tabs,
      translate: [{ getPath: 'stringId', setPath: 'name' }],
    };
  }, [fieldsComponents, mode]);

  return (
    <Core.Spinner spin={busy}>
      <Styled className={cn('materialCompliance-editor', { tabbed: tabsConfig?.tabs?.length > 1 })}>
        {tabsConfig?.tabs?.length > 1 ? <Core.Tabs config={tabsConfig} /> : tabsConfig?.tabs?.[0]?.component}
        <div className="actions">
          <div>
            <Components.CancelButton onCancel={closeDrawer} enabled />
            <Components.SaveButton onCreate={handleSave} onUpdate={handleEdit} suppressDisabledStyling />
          </div>
        </div>
      </Styled>
    </Core.Spinner>
  );
};
