import React from 'react';
import { Responsive, useQrCode } from 'connex-cds';
import { useParams } from 'react-router-dom';

export const BuildQrCode = ({ summaryRef }) => {
  const { entityRef } = useParams();

  const { origin } = window?.location;

  const qrCodeData = React.useMemo(() => {
    if (summaryRef) {
      const data = btoa(`${entityRef}::${summaryRef}`);

      return `${origin}/summary/${data}`;
    }

    return '';
  }, [entityRef, summaryRef]);

  const qrCodeLarge = useQrCode({ data: qrCodeData });
  const qrCodeSmall = useQrCode({ data: qrCodeData, size: 'xsmall' });

  return (
    <Responsive>
      {qrCodeSmall}
      {qrCodeLarge}
    </Responsive>
  );
};
