import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--color-page-background);
    overflow: auto;
    height: inherit;

    .step-content {
      overflow-y: unset;
      .step-component {
        .content-row {
          overflow: inherit;
        }
      }
    }

    .overview {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      padding-top: 5px;
      .step {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        color: #1890ff;
        cursor: pointer;
        padding: 0;
        transition: background-color 0.3s;
        &.active {
          color: #1890ff;
          flex-direction: column;
          .label {
            text-decoration: underline;
            font-size: 12px;
            &.shortened-label {
              text-overflow: unset;
              max-width: fit-content;
            }
          }

          .icon {
          }
        }

        .label {
          font-weight: 500;
          font-size: 10px;
          margin-bottom: 5px;
          &.shortened-label {
            max-width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .emptyLabel {
          height: 22px;
        }

        &:not(.active) {
          &:not(.disabled) {
            &.ok {
              color: #187c18;
            }

            &.mandatory {
              color: #bb9a1c;
            }

            &.error {
              color: red;
            }

            &.default {
              color: #aaa;
            }
          }
        }

        &.disabled {
          cursor: not-allowed;
          color: #aaa;
          .label {
            font-style: italic;
            font-weight: 300;
          }
        }

        .icon {
          width: 45px;
          height: 21px;
          font-size: 1.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-background-page);
          transition: all 0.3s;
          padding: 0 10px;
          z-index: 1;
          margin-bottom: 5px;
          background-color: var(--color-background-container);
        }

        &.default {
          .icon {
            font-size: 0.8em;
          }
        }
      }

      & > * {
        &:first-child {
          :after {
            position: absolute;
            top: 36px;
            left: 5%;
            display: block;
            width: 90%;
            height: 2px;
            content: '';
            background-color: #ccc;
          }
        }
      }
    }
  }
`;
