import { Drawer, Form, usePostMessageContext } from 'connex-cds';
import React from 'react';
import { useDataStore } from './datastore/DataStore';
import { DriverAcceptance } from './DriverAcceptance';
import { useCompanySetup } from './MasterDataProvider';
import { useTicketContext } from './TicketContext';
import { useViewport } from '../../../../util/useViewport';

const FormSubmit = ({ children }) => {
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { setOnSubmit } = Form.useFormContext();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();
  const { reset, setSubmitted } = useTicketContext();
  const { setFieldValue } = Form.useFormContext();

  const dimensions = useViewport();

  const saveTicket = React.useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      sendMessage(JSON.stringify({ type: 'submit' }));
      return response;
    },
    [submitTicket, sendMessage]
  );

  const handleCancel = React.useCallback(() => {
    setFieldValue('driverAcceptanceReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const companySetup = useCompanySetup();

  const onSubmit = React.useCallback(
    values => {
      if (values?.signature?.image || values?.driverAcceptanceReason || values?.customerRejectReason) {
        return saveTicket(values).then(() => {
          setSubmitted(true);
          reset(!companySetup?.data?.keepTicketOpenAfterSubmission === true);
        });
      }

      openDrawer({
        titleStringId: 'driverAcceptance',
        component: <DriverAcceptance />,
        width: Math.min(750, dimensions?.width),
        onClose: handleCancel,
      });
    },
    [
      companySetup?.data?.keepTicketOpenAfterSubmission,
      dimensions?.width,
      handleCancel,
      openDrawer,
      reset,
      saveTicket,
      setSubmitted,
    ]
  );

  React.useEffect(() => {
    setOnSubmit(onSubmit);
  }, [onSubmit, setOnSubmit]);

  return children;
};

export default FormSubmit;
