import React from 'react';
import { Form, Localization } from 'connex-cds';
import * as Yup from 'yup';
import { find } from 'lodash';
import { ProductEditor } from '../custom-editors/product-editor/ProductEditor';
import { FILTER_STRATEGY } from './standard';
import { buildLanguageFields } from '../languagesNames';
const { FIELD_TYPES } = Form;

// TODO: Implement standard boolean filterFn, formatter and filterComponent

const fields = (countries, setupVehicleTypes, companySetup) => {
  const _fields = [
    {
      path: 'type',
      labelStringId: 'type',
      testId: 'type',
      dataType: FIELD_TYPES.string,
      listTypeId: 'product-type',
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'name',
      formatter: props => (
        <div data-testclass={'cell-column-type'} data-testid={`row-${props.row.crn}-column-type`}>
          {props.row.type}
        </div>
      ),
      isRequired: true,
    },
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string, isRequired: true },
    {
      path: 'name',
      labelStringId: 'name',
      testId: 'name',
      dataType: FIELD_TYPES.string,
      isRequired: true,
    },
    {
      path: 'uomCode',
      labelStringId: 'uom',
      testId: 'uomCode',
      listValuePath: 'id',
      listDisplayPath: 'description',
      dataType: FIELD_TYPES.string,
      listTypeId: 'uom',
      listValues: [],
      formatter: props => (
        <div data-testclass={'cell-column-uomCode'} data-testid={`row-${props.row.crn}-column-uomCode`}>
          <Localization.Translate stringId={`${props.row.uomCode}_text`} data-testid="" />
        </div>
      ),
      isRequired: true,
    },
    {
      path: 'countries',
      labelStringId: 'countries',
      testId: 'countries',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'label',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {countries?.map?.(country => (
              <option value={country.id} key={country.id}>
                {country.label}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-countries'} data-testid={`row-${props.row.crn}-column-countries`}>
          {props.row.countries?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'vehicleTypes',
      labelStringId: 'vehicleTypes',
      testId: 'vehicleTypes',
      dataType: FIELD_TYPES.array,
      arrayOf: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'description',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {setupVehicleTypes?.map?.(vehicleType => (
              <option value={vehicleType.id} key={vehicleType.id}>
                {vehicleType.description}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-vehicleTypes'} data-testid={`row-${props.row.crn}-column-vehicleTypes`}>
          {props.row.vehicleTypes
            ?.map?.(vehicleType => find(setupVehicleTypes, { id: vehicleType })?.description)
            ?.join?.(', ')}
        </div>
      ),
    },
    {
      path: 'isDriverSellable',
      labelStringId: 'driver-sellable',
      testId: 'driverSellable',
      dataType: FIELD_TYPES.boolean,
      formatter: props =>
        props.row.isDriverSellable ? (
          <div
            data-testclass={'cell-column-isDriverSellable'}
            data-testid={`row-${props.row.crn}-column-isDriverSellable`}
          >
            Yes
          </div>
        ) : (
          <div
            data-testclass={'cell-column-isDriverSellable'}
            data-testid={`row-${props.row.crn}-column-isDriverSellable`}
          >
            No
          </div>
        ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'mobileTicket.maxQuantity',
      labelStringId: 'maxQuantity',
      testId: 'maxQuantity',
      dataType: FIELD_TYPES.string,
      hidden: true,
    },
    {
      path: 'mobileTicket.mandatory',
      labelStringId: 'mandatory',
      testId: 'mandatory',
      dataType: FIELD_TYPES.boolean,
      hidden: true,
      formatter: props => (
        <div data-testclass={'cell-column-mandatory'} data-testid={`row-${props.row.crn}-column-mandatory`}>
          {props.row.mobileTicket?.mandatory ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'mobileTicket.isConcreteOnTruckFieldEnabled',
      labelStringId: 'concreteOnTruckField',
      testId: 'concreteOnTruckField',
      dataType: FIELD_TYPES.boolean,
      defaultValue: true,
      hidden: true,
      formatter: props => (
        <div
          data-testclass={'cell-column-isConcreteOnTruckFieldEnabled'}
          data-testid={`row-${props.row.crn}-column-isConcreteOnTruckFieldEnabled`}
        >
          {props.row.mobileTicket?.isConcreteOnTruckFieldEnabled ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'mobileTicket.isTimeFieldEnabled',
      labelStringId: 'timeField',
      testId: 'timeField',
      dataType: FIELD_TYPES.boolean,
      defaultValue: true,
      hidden: true,
      formatter: props => (
        <div
          data-testclass={'cell-column-isTimeFieldEnabled'}
          data-testid={`row-${props.row.crn}-column-isTimeFieldEnabled`}
        >
          {props.row.mobileTicket?.isTimeFieldEnabled ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'mobileTicket.isReasonFieldEnabled',
      labelStringId: 'reasonField',
      testId: 'reasonField',
      dataType: FIELD_TYPES.boolean,
      defaultValue: true,
      hidden: true,
      formatter: props => (
        <div
          data-testclass={'cell-column-isReasonFieldEnabled'}
          data-testid={`row-${props.row.crn}-column-isReasonFieldEnabled`}
        >
          {props.row.mobileTicket?.isReasonFieldEnabled ? 'Yes' : 'No'}
        </div>
      ),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'isCertificationLossTrigger',
      labelStringId: 'isCertificationLossTriggerForProduct',
      testId: 'isCertificationLossTrigger',
      dataType: Form.FIELD_TYPES.boolean,
      formatter: props => {
        return (
          <div data-testclass={'cell-column-product'} data-testid={`row-${props.row.crn}-column-isCertificationLossTrigger`}>
            <input type="checkbox" name={props.row.description} checked={Boolean(props.row.isCertificationLossTrigger)} />
            <label for={props.row.description}>{` `}</label>
          </div>
        );
      },
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
      isRequired: true,
    },
    ...buildLanguageFields({ companySetup }),
  ];

  const validationSchema = Yup.object().shape(
    _fields.reduce((acc, cur) => {
      if (cur?.isRequired) {
        return { ...acc, [cur?.path]: Yup.string().required('required') };
      }

      return acc;
    }, {})
  );

  return {
    icon: <i className="icon fa-thin fa-table-cells" />,
    customEditor: ProductEditor,
    validationSchema,
    fields: _fields,
  };
};

export default fields;
