import { processToken } from './handlers';

const escapeRegexChars = str => str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&');

const getTokens = template => {
  const found = [];
  const rxp = /{([^}]+)}/g;
  let curMatch;

  while ((curMatch = rxp.exec(template))) {
    found.push(curMatch[1]);
  }

  return found;
};

const renderUnicode = text =>
  text?.replace?.(/\\u[\dA-F]{4}/gi, match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));

const processTemplate = ({ context, templateString, util }) => {
  const tokens = getTokens(templateString);

  tokens.forEach(token => {
    const value = processToken({ token, context, util });

    const pattern = escapeRegexChars(`{${token}}`);
    const regex = new RegExp(pattern, 'g');

    templateString = templateString.replace(regex, value);
  });

  return renderUnicode(templateString);
};

export default processTemplate;
