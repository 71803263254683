import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { DynamicWaiver } from './dynamic-waiver/DynamicWaiver';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import { PriorSignatures } from './prior-signatures/PriorSignatures';
import style from './style';

const { Column, Row } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const AcceptanceDesktop = ({
  handleBackClick,
  signed,
  flow,
  activeFormsAndWaivers,
  entitySupportedLanguages,
}) => {
  const { finalized, submitted } = useTicketContext();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const showDynamicWaiver = React.useMemo(() => {
    return !!companySetup?.data?.isDynamicWaiverEnabled && activeFormsAndWaivers?.length !== 0;
  }, [activeFormsAndWaivers?.length, companySetup?.data?.isDynamicWaiverEnabled]);

  const showPriorSignatures = React.useMemo(() => {
    return values?.damageWaiverSignature?.image || values?.waterReleaseSignature?.image;
  }, [values.damageWaiverSignature, values.waterReleaseSignature]);

  return (
    <Styled className={cn('acceptance-desktop')}>
      <Column>
        {finalized && (
          <div className="header">
            {signed || submitted ? null : (
              <Core.Button
                size="small"
                type="primary"
                stringId="back"
                data-testid="back-button"
                onClick={handleBackClick}
              />
            )}
            <Typography.H3>
              <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
            </Typography.H3>
            <Header flow={flow} entitySupportedLanguages={entitySupportedLanguages} />
          </div>
        )}
        <Row flex={1}>
          <Column flex={1} style={{ height: '420px' }}>
            <LogisticalInfo ticket={values} />
          </Column>
          <Column flex={2}>
            <Row flex={1} style={{ minHeight: '89px' }}>
              <Activity />
            </Row>
            <Row flex={1} style={{ minHeight: '98px' }}>
              <Comments />
            </Row>
            <Row flex={1}>
              {showPriorSignatures && (
                <Column>
                  <PriorSignatures />
                </Column>
              )}
              {showDynamicWaiver && (
                <Column>
                  <DynamicWaiver />
                </Column>
              )}
            </Row>

            <Row flex={1}>
              <FinalizeSection ticketId={values.id} flow={flow} />
            </Row>
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
