import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { get } from 'lodash';

const Styled = styled.div`
  ${style}
`;

const TermsAndConditions = ({ activeFormsAndWaivers }) => {
  return (
    <Styled className={cn('terms-and-conditions')}>
      <div>
        <div data-testid="termsAndConditions">
          <pre className={'terms-and-conditions-value'}>{get(activeFormsAndWaivers, '[0].content', '')}</pre>
        </div>
      </div>
    </Styled>
  );
};

export default TermsAndConditions;
