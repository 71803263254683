import { http } from 'connex-cds';

export default {
  getCurrentTicket: ({ entityRef, truckNumber }) =>
    http.get({ apiName: 'mt', path: `/${entityRef}/vehicles/${truckNumber}/assignment` }),
  getTicket: ({ entityRef, ticketRef }) => http.get({ apiName: 'mt', path: `/${entityRef}/tickets/${ticketRef}` }),
  createTestTicket: ({ entityRef, ticket }) =>
    http.post({ apiName: 'mt', path: `/${entityRef}/tickets`, data: ticket }),
  updateTicket: ({ entityRef, ticket }) => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(JSON.stringify({ type: 'debug', data: ticket }), '*');
    return http.patch({ apiName: 'mt', path: `/${entityRef}/tickets/${ticket?.ticketRef}`, data: ticket });
  },
  getUnloadingMethods: entityRef => http.get({ apiName: 'mt', path: `/${entityRef}/master-data/unloading-method` }),
  getAppEntities: ({ profileRef }) => http.get({ apiName: 'mt', path: `/entities/${profileRef}/` }),
  getMtTemplatesExternal: ({ profileRef, entityRef }) =>
    http.get({ apiName: 'mt', path: `/${profileRef}/master-data-external/${entityRef}/templates-mt-external` }),
};
