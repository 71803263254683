import { Form, Localization, Responsive } from 'connex-cds';
import React from 'react';
import { RiskAssessmentMobile } from './RiskAssessmentMobile';
import { RiskAssessmentDesktop } from './RiskAssessmentDesktop';
import { useRiskAssessmentSetup, useVehicleSetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import { find } from 'lodash';

export const RiskAssessment = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { values, setFieldValue } = Form.useFormContext();
  const vehicleSetup = useVehicleSetup();
  const riskAssessmentSetup = useRiskAssessmentSetup();

  const { ticket } = useTicketContext();

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    return targetVehicle?.vehicleTypes;
  }, [ticket?.vehicleId, vehicleSetup.data]);

  const activeAssessments = React.useMemo(() => {
    if (!riskAssessmentSetup?.data) {
      return [];
    }

    const riskAssessments = riskAssessmentSetup?.data
      ?.filter?.(riskAssessment => riskAssessment?.status === 'ACTIVE')
      ?.filter?.(riskAssessment => riskAssessment?.product?.includes(vehicleType) || !riskAssessment?.product?.length);

    if (riskAssessments && riskAssessments?.length) {
      const riskAssessmentCategory = translateObjects(riskAssessments, {
        getStringId: x => `SETUP_ASSESSMENT-RISK_${x}-CATEGORY`.replace(/\s/gi, '-').toUpperCase(),
        getPath: 'crn',
        setPath: 'labelCategory',
        defaultMessagePath: 'category',
      });

      const riskAssessmentSubcategory = translateObjects(riskAssessmentCategory, {
        getStringId: x => `SETUP_ASSESSMENT-RISK_${x}-SUBCATEGORY`.replace(/\s/gi, '-').toUpperCase(),
        getPath: 'crn',
        setPath: 'labelSubcategory',
        defaultMessagePath: 'subcategory',
      });

      return riskAssessmentSubcategory.map(risk => ({
        ...risk,
        category: risk?.labelCategory ?? risk?.category,
        subcategory: risk?.labelSubcategory ?? risk?.subcategory,
      }));
    }

    return riskAssessments;
  }, [riskAssessmentSetup.data, vehicleType, translateObjects]);

  const categories = activeAssessments?.reduce((categories, item) => {
    const category = categories[item.category] || [];
    category.push(item);
    categories[item.category] = category;
    return categories;
  }, {});

  const handleYesClick = React.useCallback(
    item => {
      const idx = values?.riskAssessment?.findIndex(risk => risk.subcategory === item.subcategory);
      if (idx > -1) {
        setFieldValue(`riskAssessment[${idx}].passAssessment`, true);
      } else {
        setFieldValue(`riskAssessment[${values?.riskAssessment?.length}]`, {
          category: item.category,
          subcategory: item.subcategory,
          passAssessment: true,
        });
      }
    },
    [setFieldValue, values?.riskAssessment]
  );

  const handleNoClick = React.useCallback(
    item => {
      const idx = values?.riskAssessment?.findIndex(risk => risk.subcategory === item.subcategory);
      if (idx > -1) {
        setFieldValue(`riskAssessment[${idx}].passAssessment`, false);
      } else {
        setFieldValue(`riskAssessment[${values?.riskAssessment?.length}]`, {
          category: item.category,
          subcategory: item.subcategory,
          passAssessment: false,
        });
      }
    },
    [setFieldValue, values?.riskAssessment]
  );

  return (
    <Responsive>
      <RiskAssessmentMobile categories={categories} handleYesClick={handleYesClick} handleNoClick={handleNoClick} />
      <RiskAssessmentDesktop categories={categories} handleYesClick={handleYesClick} handleNoClick={handleNoClick} />
    </Responsive>
  );
};
