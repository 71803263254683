import cn from 'classnames';
import { Form, Core } from 'connex-cds';
import { isEmpty, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { FLOW } from '../Acceptance';

const Styled = styled.div`
  padding: 30px;

  button {
    width: 200px;
    height: 50px;
    font-size: 1.2em;
  }

  &.finalize {
    flex: 1;
    margin-bottom: 20px;

    .actions {
      position: fixed;
      bottom: 31px;
      right: 45px;
    }
  }

  &.accept-reject {
    margin-left: 60px;
    & > * {
      &:first-child {
        margin-bottom: 20px;
        button {
          background: green;
        }
      }
      &:last-child {
      }
    }

    @media (max-width: 950px) {
      display: flex;
      & > * {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
`;
export const FinalizeActionsMobile = ({ finalized, flow, handleAcceptClick, handleRejectClick, handleClick }) => {
  const { Components, errors } = Form.useFormContext();

  return finalized ? (
    <Styled className={cn('finalize-actions accept-reject')}>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button stringId="accept" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        ) : (
          <Core.Button stringId="signature" onClick={handleAcceptClick} type="primary" data-testid="accept-button" />
        )}
      </div>
      <div>
        {flow === FLOW.ACCEPT_REJECT ? (
          <Core.Button
            danger
            stringId="reject"
            onClick={handleRejectClick}
            type="primary"
            data-testid="reject-button"
          />
        ) : (
          <Components.SubmitButton
            stringId="no-signature"
            data-testid="no-signature-button"
            type="primary"
            danger
            disabledWhenNotDirty={false}
            disabled={!isEmpty(omit(errors, 'ticketEvents'))}
          />
        )}
      </div>
    </Styled>
  ) : (
    <Styled className={cn('finalize-actions finalize')}>
      <div className="actions">
        <Core.Button stringId="finalize" onClick={handleClick} type="primary" data-testid="finalize-button" />
      </div>
    </Styled>
  );
};
