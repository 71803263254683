import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import React from 'react';
import { useTicketContext } from '../../../TicketContext';
import { TicketDetailDesktop } from './TicketDetailDesktop';
import { TicketDetailMobile } from './TicketDetailMobile';
import { activeFormsAndWaiversArray } from '../../../getActiveForms';
import { useCompanySetup, useFormsAndWaiversSetup, useVehicleSetup } from '../../../MasterDataProvider';

export const TicketDetail = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { validateForm, resetForm } = Form.useFormContext();
  const { ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const companySetup = useCompanySetup();
  const vehicleSetup = useVehicleSetup();

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  React.useEffect(() => {
    if (ticket === null) {
      resetForm({ reinitialize: true });
    }
  }, [resetForm, ticket]);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'DAMAGE',
        formsAndWaiversSetup,
        vehicleSetup,
        companySetup,
        translateObjects,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, companySetup, translateObjects]
  );

  return (
    <Drawer.DrawerProvider>
      <Responsive>
        <TicketDetailMobile activeFormsAndWaivers={activeFormsAndWaivers} />
        <TicketDetailDesktop activeFormsAndWaivers={activeFormsAndWaivers} />
      </Responsive>
    </Drawer.DrawerProvider>
  );
};
