import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Ratować',
  username: 'Nazwa użytkownika',
  password: 'Hasło',
  login: 'Zaloguj sie',
  required: 'Wymagany',
  light: 'Światło',
  dark: 'Ciemny',
  rememberMe: 'Zapamiętaj mnie',
  eula: 'Eula',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Polityka prywatności',
  customer: 'Klient',
  project: 'Projekt',
  isBillable: 'Rozliczalny',
  dispatchDateTime: 'Data',
  orderId: 'Zamówienie',
  ticketId: 'Bilet',
  location: 'Lokalizacja',
  vehicleTypeName: 'Pojazd',
  product: 'Produkt',
  products: 'Produkt',
  salesTax: 'Podatek od sprzedaży',
  totalPrice: 'Całkowity',
  extendedPrice: 'Ext',
  destination: 'Miejsce docelowe',
  productId: 'ID produktu',
  statusCode: 'Status',
  vehicleTypeId: 'Identyfikator typu pojazdu',
  locationId: 'Identyfikator lokalizacji',
  customerId: 'Identyfikator klienta',
  projectId: 'Identyfikator projektu',
  dispatchTime: 'Czas',
  showAll: 'Pokaż wszystko',
  hideAll: 'Schowaj wszystko',
  undoChanges: 'Cofnij zmiany',
  resetColumns: 'Zresetuj kolumny',
  displayRowCount: `Wyświetlanie {count, plural, 
    =0 {0 wydziwianie} 
    one {# wiersz} 
    other {# wydziwianie} 
  }`,
  columnFilters: 'Filtry kolumn',
  visible: 'Widoczny',
  hidden: 'Ukryty',
  itemDetail: 'Szczegóły elementu',
  quantity: 'Ilość',
  unloadingMethod: 'Metoda rozładunku',
  time: 'Czas',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'Ea',
  LTR_abbr: 'L',
  YDQ_abbr: 'Cy',
  GLL_abbr: 'GAL',
  MTQ_text: 'Metry sześcienne',
  M3_text: 'Metry sześcienne',
  EA_text: 'Każdy',
  LTR_text: 'Litry',
  YDQ_text: 'Jardy sześcienne',
  GLL_text: 'Galony',
  ACR_text: 'Włości',
  HUR_text: 'Godzina',
  INH_text: 'Cale',
  FOT_text: 'Stopa',
  YRD_text: 'Dziedziniec',
  MMT_text: 'Milimetr',
  CMT_text: 'Centymetr',
  MTR_text: 'Metr',
  SMI_text: 'Mile (statut Mile)',
  KMT_text: 'Kilometr',
  INK_text: 'Cal kwadratowy',
  FTK_text: 'Stóp kwadratowych',
  YDK_text: 'Jard kwadratowy',
  MIK_text: 'Mila kwadratowa',
  CMK_text: 'Kwadratowy centymetr',
  MTK_text: 'Metr kwadratowy',
  KMK_text: 'Kilometr kwadratowy',
  INQ_text: 'Cal sześcienny',
  MMQ_text: 'Sześcienny milimetr',
  FTQ_text: 'Stopa sześcienna',
  CMQ_text: 'Centymetr sześcienny',
  OZA_text: 'Uncja płynu',
  PT_text: 'Pół kwarty',
  QT_text: 'Kwarta',
  MLT_text: 'Milliter',
  ONZ_text: 'Uncja',
  LBR_text: 'Funt',
  STN_text: 'Tona',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Ton',
  PS_text: 'Psi',
  MPA_text: 'Megapascal',
  C56_text: 'Newton na kwadratowy milimetr',
  C62_text: 'Jeden',
  NL_text: 'Obciążenie',
  MT_text: 'Mata',
  P1_text: 'Procent',
  CEL_text: 'Stopień Celsjusza',
  FAH_text: 'Stopień Fahrenheit',
  BLL_text: 'Beczka',
  CLT_text: 'Centilitre',
  DLT_text: 'Decylitr',
  GLI_text: 'Galon',
  '2U_text': 'Megagram',
  SA_text: 'Worek',
  '60_text': 'Procent wagi',
  BG_text: 'Torba',
  SEC_text: 'Drugi',
  MIN_text: 'Minuta',
  '64_text': 'Funt na cal kwadratowy - miernik',
  SP_text: 'Pakiet półki',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Each}
    one {1 Each}
    other {# Each}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    one {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    one {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    one {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    one {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    one {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    one {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeters}
    one {1 Millimeter}
    other {# Millimeters}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimeters}
    one {1 Centimeter}
    other {# Centimeters}
  }`,
  MTR: `{value, plural,
    =0 {0 Meters}
    one {1 Meter}
    other {# Meters}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    one {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometers}
    one {1 Kilometer}
    other {# Kilometers}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    one {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    one {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    one {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    one {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimeters}
    one {1 Square Centimeter}
    other {# Square Centimeters}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    one {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometers}
    one {1 Square Kilometer}
    other {# Square Kilometers}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    one {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimeters}
    one {1 Cubic Millimeter}
    other {# Cubic Millimeters}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    one {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimeters}
    one {1 Cubic Centimeter}
    other {# Cubic Centimeters}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    one {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    one {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    one {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    one {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    one {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    one {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    one {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimeter}
    one {1 Newton Per Square Millimeter}
    other {# Newtons Per Square Millimeter}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    one {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    one {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    one {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    one {1 Percent}
    other {# Percent}
  }`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    one {1 Degree Celsius}
    other {# Degrees Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    one {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    one {1 Barrel}
    other {# Barrels}
  }`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    one {1 Centilitre}
    other {# Centilitres}
  }`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    one {1 Decilitre}
    other {# Decilitres}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    one {1 Gallon}
    other {# Gallons}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    one {1 Megagram}
    other {# Megagrams}
  }`,
  SA: `{value, plural,
    =0 {0 Sacks}
    one {1 Sack}
    other {# Sacks}
  }`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    one {1 Percent Weight}
    other {# Percent Weight}
  }`,
  BG: `{value, plural,
    =0 {0 Bags}
    one {1 Bag}
    other {# Bags}
  }`,
  SEC: `{value, plural,
    =0 {0 Seconds}
    one {1 Second}
    other {# Seconds}
  }`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    one {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
  }`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    one {1 Shelf Package}
    other {# Shelf Packages}
  }`,
  mixIdLabel: 'Mix Id/Opis',
  orderedQtyLabel: 'Zamówiono Ilość',
  loadedQtyLabel: 'Załadowana Ilość',
  addedByCustomer: 'Dodane przez klienta',
  PRINTED: 'Bilet',
  LOADING_STARTED: 'Rozpocznij ładowanie',
  LOADING_COMPLETE: 'Zakończenie ładowania',
  TO_JOB: 'Lewa roślina',
  ARRIVE_JOB: 'Przyjeżdża strona',
  UNLOADING: 'Zacznij rozładować',
  END_UNLOADING: 'Zakończ rozładunek',
  LEAVE_JOB: 'Opuść pracę',
  IN_YARD: 'Na podwórku',
  PUMPING_STARTED: 'Rozpocznij pompę',
  PUMPING_COMPLETE: 'Pompa wykończenia',
  READY_TO_PUMP: 'Gotowy do pompowania',
  FIRST_WCCONTACT: 'Pierwszy kontakt z cementu wodnym',
  plantName: 'Nazwa rośliny',
  plantAddress: 'Adres rośliny',
  loadingScheduleStart: 'Planowany czas ładowania',
  deliveryAddress: 'Adres dostawy',
  phoneNumber: 'Numer telefonu',
  unloadingScheduleStart: 'Planowany czas rozładunku (ETA)',
  agreedUnloading: 'Zamów czas rozładunku',
  deliveryInformation: 'Informacje o dostawie',
  next: 'Następny',
  material: 'Materiał',
  loadingPoint: 'Punkt załadunku',
  unloadingPoint: 'Punkt rozładunku',
  comment: 'Komentarz',
  sapDeliveryNumber: 'Numer dostawy SAP (numer BCC)',
  salesOrderNumber: 'Numer zamówienia sprzedaży i linia przedmiotów',
  wcDateTime: 'Data i godzina w/c kontakt',
  po: 'numer PO',
  orderedByName: 'Zamówienie złożone według nazwy',
  orderedByPhone: 'Zamówienie złożone według numeru telefonu',
  resourceId: 'Identyfikator zasobów',
  totalLoadedQty: 'Całkowite załadowana Ilość, w tym ten obciążenie',
  spacing: 'Obciążenie',
  deliveryFlow: 'Przepływ dostawy',
  timeOnSite: 'Całkowity czas na witrynie/zamówienie czasu rozładunku',
  salesText: 'Tekst sprzedaży (identyfikator materiału)',
  strengthClass: 'Klasa siły',
  environmentExposure: 'Ekspozycja na środowisko',
  metExposureClasses: 'Met Zajęcia narażenia',
  dmax: 'Dmax',
  standardCert: 'Standard (certyfikacja)',
  workabilityTarget: 'Cel pracy klienta',
  stoneQuality: 'Jakość kamienia',
  sandQuality: 'Jakość piasku',
  targetWC: 'Docelowy stosunek w/c z przepisu',
  controlClass: 'Klasa kontrolna',
  chlorideClass: 'Klasa chlorkowa',
  typeAndStrength1: 'Rodzaj cementu i klasa siły 1',
  typeAndStrength2: 'Rodzaj cementu i klas siły 2',
  typeOfAddition: 'Rodzaj dodawania',
  typeOfAdmixture: 'Rodzaj domieszki',
  orderedTemp: 'Zamówiono temperaturę',
  fibers1: 'Typ i zawartość włókien 1',
  fibers2: 'Typ i zawartość włókien 2',
  calculatedMaturityTime: 'Obliczony czas dojrzałości od czasu kontaktu W/C',
  mixIdentity: 'Mieszaj tożsamość',
  grade: 'Stopień',
  mixType: 'Mieszaj typ',
  maxAggSize: 'Max Agg Rozmiar',
  maxAggType: 'MAX AGG Typ',
  cementType: 'Typ cementu',
  admixtures: 'Domieszki',
  addmixDescription: 'Opis domieszki',
  slump: 'Kryzys',
  minCementContent: 'Zawartość cementu min',
  maxWatCemRatio: 'Maksymalny stosunek wody/cementu',
  maxWaterToAdd: 'Maksymalna woda do dodania',
  mortarMinWorkingLife: 'Moździerz minimalny',
  code: 'Kod',
  dcClass: 'DCCLASS',
  chloride: 'Chlorek',
  signedByHanson: 'Podpisane przez Hansona',
  warmStamp: 'Ciepły znaczek',
  coldStamp: 'Zimny stempel',
  containsSikatard: 'Zawiera Sikatard',
  containsDelvo: 'Zawiera Delvo',
  generalDelivery: 'Informacje o dostawie ogólnej',
  materialInfo: 'Informacje materialne',
  water: 'Woda',
  materialService: 'Materiał / usługa',
  returnedMaterial: 'Zwrócony materiał',
  returnedConcrete: 'Zwrócony beton',
  waterAdded: 'Dodano wodę',
  concreteOnTruck: 'Beton na ciężarówce',
  concreteOnTruckUom: 'Domyślny beton na ciężarówce UOM',
  reason: 'Powód',
  customerAddedWater: 'Klient dodał wodę',
  addWater: 'Dodaj wodę',
  addedAt: 'Dodano o',
  addMaterialService: 'Dodaj materiał/usługę',
  customerAddedProduct: 'Klient dodał produkt',
  addReturnedMaterial: 'Dodaj zwrócony materiał',
  leftover: 'Resztki',
  submitAcceptance: 'Prześlij akceptację',
  accepted: 'Przyjęty',
  customerAcceptance: 'Przyjęcie',
  finalize: 'Sfinalizować',
  materialSummary: 'Podsumowanie materiału',
  mix: 'Mieszać',
  ordered: 'Zamówiono',
  loaded: 'Obciążenie dostawcze',
  totalOrdered: 'Całkowita ilość zamówienia',
  loadedOrderQuantity: 'Załadowana ilość zamówienia',
  loadedOrder: 'Załadowane zamówienie',
  driverComment: 'Komentarz kierowcy',
  customerComment: 'Komentarz klienta',
  editComment: 'Edytuj komentarz',
  addComment: 'Dodaj komentarz',
  additionalInfo: 'Dodatkowe informacje',
  onBoard: 'Na pokładzie',
  driver: 'Kierowca',
  commentInstruction: 'Instrukcja komentarza',
  signatureInstruction: 'Podpis klienta',
  customerSignature: 'Podpis klienta',
  general: 'Ogólny',
  status: 'Status',
  updateStatusTime: 'Zaktualizuj czas statusu',
  delete: 'Usuwać',
  cancel: 'Anulować',
  back: 'Z powrotem',
  add: 'Dodać',
  done: 'Zrobione',
  ok: 'OK',
  decline: 'Spadek',
  Submit: 'Składać',
  submit: 'Składać',
  Time: 'Czas',
  clear: 'jasne',
  lastRefresh: 'Ostatnie odświeżenie',
  refreshEnabled: 'Włącz odświeżenie',
  id: 'ID',
  name: 'Nazwa',
  invoiceable: 'Faktura',
  provideSignature: 'Zapewnij podpis',
  ticket: 'Bilet',
  details: 'Detale',
  qrCode: 'Kod QR',
  driverAcceptanceReason: 'Powód',
  driverAcceptance: 'Akceptacja kierowcy',
  'payment-method': 'Metody Płatności',
  'add-payment-method': 'Dodaj metodę płatności',
  'reason-code': 'Kod powodu',
  'add-reason-code': 'Dodaj kod przyczyny',
  'qr-code': 'Kod QR',
  'unloading-method': 'Metody rozładunku',
  'add-unloading-method': 'Dodaj metodę rozładunku',
  'driver-comment': 'Komentarze kierowcy',
  'add-driver-comment': 'Dodaj komentarz sterownika',
  'driver-sellable': 'Wyprzedaże kierowcy',
  vehicle: 'Pojazdy',
  'add-vehicle': 'Dodaj pojazd',
  'vehicle-type': 'Typy pojazdów',
  vehicleTypes: 'Typy pojazdów',
  vehicleType: 'Typ pojazdu',
  'add-vehicle-type': 'Dodaj typ pojazdu',
  countries: 'Kraje',
  country: 'Kraj',
  type: 'Typ',
  uom: 'Uom',
  mandatory: 'Obowiązkowy',
  qrTemplate: 'Szablon kodu QR',
  includeMixInfo: 'Dołącz informacje o miksie w kodzie QR',
  includeTicketEvents: 'Uwzględnij wydarzenia biletowe w kodzie QR',
  quantityField: 'Włącz pole ilości',
  concreteOnTruckField: 'Włącz beton na polu ciężarówki',
  timeField: 'Włącz pole czasu',
  reasonField: 'Włącz pole powodu',
  driverInstructions: 'Instrukcje kierowcy',
  waitingForTicket: 'Czekam na bilet ...',
  createTestTicketForTruck: 'Utwórz bilet testowy na ciężarówkę',
  exceedsLoadedQuantity: 'Wartość nie może przekraczać obciążenia ilości {loadedQuantity}.',
  maxQuantity: 'Maksymalna ilość',
  maxQuantityError: 'Ilość nie może przekroczyć zdefiniowanej maksymalnej ilości {maxQty}',
  maxValueError: 'Wartość nie może przekroczyć zdefiniowanego maksimum {maxQty}',
  minValueError: 'Wartość nie może być mniejsza niż określone minimum {minQty}',
  notAuthorized: 'Nieautoryzowany',
  enterTruckNumber: 'Wprowadź numer ciężarówki',
  setTruckNumber: 'Ustaw numer ciężarówki',
  commentList: 'Lista komentarzy',
  list: 'Lista',
  gridPreferences: 'Preferencje siatki',
  loadingUnloadingPoints: 'Punkty ładowania/rozładowywania',
  activity: 'Działalność',
  crn: 'Crn',
  update: 'Aktualizacja',
  CONCRETE: 'Beton',
  WATER: 'Woda',
  ADDITIVE: 'Przyłączeniowy',
  'submit-signature-button': 'Zaakceptować',
  'cancel-signature-button': 'Anulować',
  uomCode: 'Domyślnie UOM',
  'product-type': 'Typy produktów',
  'add-product-type': 'Dodaj typ produktu',
  'add-product': 'Dodaj produkt',
  'setup_driver-comment': 'Komentarze kierowcy',
  setup_product: 'Produkty',
  'setup_product-type': 'Typy produktów',
  'setup_qr-code': 'Kod QR',
  'setup_reason-code': 'Kody powodów',
  'setup_unloading-method': 'Metody rozładunku',
  'setup_entity-mt': 'Firma',
  setup_vehicle: 'Pojazdy',
  'setup_vehicle-type': 'Typy pojazdów',
  'setup_data-retentions': 'Zatrzymanie danych',
  'add-data-retentions': 'Dodaj retencję danych',
  'data-retentions': 'Zatrzymanie danych',
  setup_carriers: 'Przewoźnicy',
  'add-carriers': 'Dodaj przewoźniki',
  carriers: 'Przewoźnicy',
  'setup_concrete-classes': 'Zgodność materialna',
  'add-concrete-classes': 'Dodaj materialną zgodność',
  'concrete-classes': 'Zgodność materialna',
  'setup_forms-and-waivers': 'Formularze i zwolnienia',
  'add-forms-and-waivers': 'Dodaj formy i zwolnienia',
  'forms-and-waivers': 'Formularze i zwolnienia',
  companyFleet: 'Flota firmy',
  category: 'Kategoria',
  interval: 'Interwał',
  orders: 'Zamówienia i bilety',
  '30days': '30 dni',
  '60days': '60 dni',
  '90days': '90 dni',
  '1year': '1 rok',
  '3years': '3 lata',
  waterMandatory: 'Woda obowiązkowa',
  qrCodeTemplate: 'Szablon kodu QR',
  waterConfig: 'Opcjonalne pola',
  waterProduct: 'Woda',
  waterProductType: 'Typ produktu wodnego',
  defaultWaterProduct: 'Domyślny produkt wodny',
  invalidTemplate: 'Nieprawidłowy szablon',
  siteAddress: 'Adres miejsca',
  siteContactName: 'Nazwa kontaktu witryny',
  siteContactPhoneNumber: 'Witryna kontaktowa numer telefonu',
  'clear-signature-button': 'Wyraźny podpis',
  customerCommentEnabled: 'Włącz komentarz klienta',
  driverCommentEnabled: 'Włącz komentarz kierowcy',
  driverCommentMaxCharacters: 'Maksymalna długość',
  customerCommentMaxCharacters: 'Maksymalna długość',
  timeZone: 'Strefa czasowa',
  driverDidNotAdd: 'Kierowca nie dodał',
  maxLineItems: 'Maksymalna liczba elementów linii',
  oneLineItem: 'Musi mieć co najmniej 1 pozycję',
  signatureSiteContact: 'Nazwa klienta',
  ticketEvents: 'Wydarzenia biletowe',
  hideEvent: 'Ukryj wydarzenie',
  editable: 'Edytowalny',
  ticketDetail: 'Bilet #{value}',
  statusTimes: 'Czasy statusu',
  materialAndDeliveryInfo: 'Informacje o materiałach i dostawach',
  customerRejection: 'Odrzucenie klienta',
  'submit-rejection-button': 'Prześlij odrzucenie',
  accept: 'Zaakceptować',
  reject: 'Odrzucić',
  signature: 'Podpis',
  'no-signature': 'Bez podpisu',
  unFinalizePassword: 'Niefinalizuj hasła',
  waterInWorkflow: 'Włącz krok wodny w przepływie pracy',
  multicountry: 'Wiele krajów',
  timestampAfterSequence: 'OSTRZEŻENIE: Czas nie powinien być przed poprzednim znacznikiem czasu',
  timestampBeforeSequence: 'OSTRZEŻENIE: Czas nie powinien być po następnym znacznik czasu',
  noRestQuantity: 'Brak ilości spoczynku',
  returnedMaterialMandatory: 'Musi wchodzić w interakcje z zwróconym krokiem materialnym',
  driverCommentMaxChars: 'Długość nie może przekroczyć maksymalnego {maxLength} znaków.',
  QrCodeEnabled: 'Włącz kod QR',
  returnedMaterialInWorkflow: 'Włącz Zwrócony Materiał Krok w przepływie pracy',
  materialServiceInWorkflow: 'Włącz krok materiału/usługi w przepływie pracy',
  totalPumpedQuantity: 'Całkowita ilość pompowana',
  concrete: 'Beton',
  materialComplianceEnabled: 'Włącz zgodność materialną',
  pumpStatusTimes: 'Czasy statusu pompy',
  pollingInterval: 'Interwał wyborczy (sekundy)',
  tenSecs: 'Interwał musi wynosić co najmniej 10 sekund',
  materialCompliance: 'Zgodność materialna',
  consistenceValue: 'Konsystencja',
  consistenceIsCompliant: 'Spójność jest zgodna z BS8500-2',
  airContentValue: 'Zawartość powietrza',
  airContentIsCompliant: 'Treść powietrza jest zgodna z BS8500-2',
  consistenceIsCompliantShort: 'Spójność jest zgodna',
  airContentIsCompliantShort: 'Treść powietrza jest zgodna',
  compliance: 'Zgodność materialna z BS8500-2',
  termsAndConditionsEnabled: 'Włącz formę warunków',
  termsAndConditions: 'Regulamin',
  damageWaiverEnabled: 'Włącz formę zrzeczenia się obrażeń',
  damageWaiver: 'Zrzeczenie się obrażeń',
  dynamicWaiver: 'Dynamiczne zrzeczenie się',
  dynamicWaiverEnabled: 'Włącz formę dynamicznego zwolnienia',
  ticketListEnabled: 'Włącz listę biletów',
  clearTicketAfterSubmission: 'Wyraźny bilet po złożeniu',
  keepTicketOpenAfterSubmission: 'Utrzymuj bilet otwarty po złożeniu',
  displayTicketList: 'Wyświetl listę biletów',
  supportedLanguages: 'Obsługiwane języki',
  allow: 'Umożliwić',
  default: 'Domyślny',
  translations: 'Tłumaczenia',
  family_name: 'Nazwisko',
  given_name: 'Imię',
  email: 'E-mail',
  role: 'Rola',
  inviteUser: 'Zaproś użytkownika',
  carrier: 'Przewoźnik',
  emailOrPhoneNumber: 'E-mail',
  usersEditor: 'Edytor użytkowników',
  verifyPassword: 'Zweryfikuj hasło',
  verificationCode: 'Kod weryfikacyjny',
  register: 'Rejestr',
  registerInstructions: 'Wypełnij ten formularz, aby sfinalizować rejestrację.',
  'password-requirements':
    'Twoje hasło musi wynosić co najmniej 8 znaków, zawiera co najmniej 1 wielki poziom, 1 małe litery, 1 liczba i 1 znak specjalny.',
  passwordMinCharacters: 'Hasło musi zawierać 8 lub więcej znaków.',
  passwordMaxCharacters: 'Hasło nie może zawierać więcej niż 99 znaków.',
  passwordLowerCase: 'Hasło musi zawierać 1 lub więcej literę o niższej liście.',
  passwordUpperCase: 'Hasło musi zawierać 1 lub więcej litery górnej skrzynki.',
  passwordNumber: 'Hasło musi zawierać 1 lub więcej znaku numerycznego.',
  passwordSpecialCharacter: 'Hasło musi obejmować 1 lub więcej znaków specjalnych.',
  passwordsDontMatch: 'Hasła nie pasują!',
  success: 'Powodzenie!',
  'registration-success': 'Twoja rejestracja zakończyła się powodzeniem.',
  'post-registration-instructions': 'Ukończyłeś proces rejestracji. Możesz się teraz zalogować.',
  createDate: 'Data Utworzenia',
  customerPo: 'Numer PO klienta',
  specification: 'Specyfikacja',
  description: 'Opis',
  classType: 'Typ klasy',
  minimumValue: 'Minimalna wartość',
  maximumValue: 'Maksymalna wartość',
  TrackIt: 'Śledź to',
  trackIt3PContext: 'Śledź kontekst 3p',
  inviteLanguage: 'Zaproś język',
  truckNumber: 'Numer ciężarówki',
  adverseTemperature:
    'Temperatura otoczenia w momencie mieszania była taka, że nie możemy zagwarantować, że beton będzie miał temperaturę w zakresie 5-35oC zgodnie z normą EN 206.',
  environmentalNote:
    'Obciążenie to zawiera czynnik środowiskowy, który nie przekracza 0,03% masy betonu. Woda zawierająca ten materiał do produkcji betonu będzie zgodny z BS EN 1008.',
  unloadingMethodEnabled: 'Włącz metodę rozładunku',
  warrantyVoided: 'Materiał unieważniony',
  isCertificationLossTriggerForReason: 'Powoduje utratę certyfikacji',
  isCertificationLossTriggerForProduct: 'Powoduje utratę certyfikacji',
  materialWarrantyVoidedEnabled:
    'Gdy stosuje się produkt do utraty certyfikacji lub kod produktu & przyczyny, pokaż notatkę o utracie certyfikacji w momencie podpisu.',
  'setup_templates-mt': 'Szablony',
  copyFrom: 'Kopiuj z ...',
  copy: 'Kopiuj',
  Copy: 'Kopiuj',
  users: 'Użytkownicy',
  setup: 'Organizować coś',
  driverExperience: 'Doświadczenie kierowcy',
  ticketList: 'Lista biletów',
  content: 'Treść',
  WARNINGS: 'Ostrzeżenia',
  TERMS: 'Warunki',
  DAMAGE: 'Szkoda',
  ADDITIONALWAIVER: 'Dodatkowe zwolnienie',
  DYNAMICWAIVER: 'Dynamiczne zrzeczenie się',
  source: 'Źródło',
  sensorUnable: 'Czujnik nie jest w stanie określić ilości resztek.',
  PROBE: 'Sonda',
  DRIVER: 'Kierowca',
  DRS: 'Drs',
  waterReleaseFormEnabled: 'Włącz formę uwalniania wody',
  waterRelease: 'Uwolnienie wody',
  'assessment-risk': 'Ocena ryzyka',
  'setup_assessment-risk': 'Ocena ryzyka',
  'add-assessment-risk': 'Dodaj ocenę ryzyka',
  subcategory: 'Podkategoria',
  isRequired: 'Jest wymagane',
  riskAssessmentEnabled: 'Włącz ocenę ryzyka',
  riskAssessment: 'Ocena ryzyka',
  yes: 'Tak',
  no: 'NIE',
  sourceOptionsSensors: 'Opcje źródła (czujniki)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonda',
  availableCustomValues: 'Dostępne wartości niestandardowe',
  ERR_CODE__DUPLICATE: 'Duplikować',
  ERR_CODE__DEFAULT_VALUE: 'Istnieje już wartość domyślna',
  ERR_CODE__EXISTING_FORM: 'Dla tego pojazdu i typu istnieje już aktywny formularz',
  availableValues: 'Dostępne wartości',
  DanskName: 'Nazwa DANSK',
  GermanName: 'Nazwa niemiecka',
  EnglishName: 'Angielskie imie',
  BritainName: 'Nazwa Wielkiej Brytanii',
  SpanishName: 'Hiszpańska nazwa',
  FrenchName: 'Nazwa francuska',
  NederlandsName: 'Nazwa Nederlands',
  NorskName: 'Nazwa Norsk',
  SvenskaName: 'Nazwa svenska',
  ItalianName: 'Imię włoskie',
  PolishName: 'Imię polskie',
  DanskNameDefault: 'Nazwa DANSK (domyślnie)',
  GermanNameDefault: 'Nazwa niemiecka (domyślnie)',
  EnglishNameDefault: 'Nazwa angielskiego (domyślnie)',
  BritainNameDefault: 'Nazwa Wielkiej Brytanii (domyślnie)',
  SpanishNameDefault: 'Hiszpańska nazwa (domyślnie)',
  FrenchNameDefault: 'Nazwa francuska (domyślnie)',
  NederlandsNameDefault: 'Nazwa Nederlands (domyślnie)',
  NorskNameDefault: 'Nazwa Norsk (domyślnie)',
  SvenskaNameDefault: 'Nazwa svenska (domyślnie)',
  ItalianNameDefault: 'Imię włoskie (domyślnie)',
  PolishNameDefault: 'Imię polskie (domyślnie)',
  DanskDescription: 'Opis DANSKA',
  GermanDescription: 'Opis niemiecki',
  EnglishDescription: 'Opis angielskiego',
  BritainDescription: 'Opis Wielkiej Brytanii',
  SpanishDescription: 'Opis hiszpański',
  FrenchDescription: 'Opis francuski',
  NederlandsDescription: 'Opis Nederlands',
  NorskDescription: 'Opis Norsk',
  SvenskaDescription: 'Opis Svenska',
  ItalianDescription: 'Opis włoski',
  PolishDescription: 'Polski opis',
  DanskDescriptionDefault: 'Opis DANSK (domyślnie)',
  GermanDescriptionDefault: 'Opis niemiecki (domyślnie)',
  EnglishDescriptionDefault: 'Opis angielskiego (domyślnie)',
  BritainDescriptionDefault: 'Opis Wielkiej Brytanii (domyślnie)',
  SpanishDescriptionDefault: 'Hiszpański opis (domyślnie)',
  FrenchDescriptionDefault: 'Opis francuski (domyślnie)',
  NederlandsDescriptionDefault: 'Opis Nederlands (domyślnie)',
  NorskDescriptionDefault: 'Opis Norsk (domyślnie)',
  SvenskaDescriptionDefault: 'Svenska Opis (domyślnie)',
  ItalianDescriptionDefault: 'Opis włoski (domyślnie)',
  PolishDescriptionDefault: 'Polski opis (domyślnie)',
  DanskContent: 'Treść DANSK',
  GermanContent: 'Treść niemiecka',
  EnglishContent: 'Treść angielska',
  BritainContent: 'Treść Wielkiej Brytanii',
  SpanishContent: 'Treść hiszpańska',
  FrenchContent: 'Treść francuska',
  NederlandsContent: 'Treść Nederlands',
  NorskContent: 'Treść NORSK',
  SvenskaContent: 'Treść svenska',
  ItalianContent: 'Treść włoska',
  PolishContent: 'Treść polska',
  DanskContentDefault: 'Zawartość DANSK (domyślnie)',
  GermanContentDefault: 'Treść niemiecka (domyślnie)',
  EnglishContentDefault: 'Treść angielska (domyślnie)',
  BritainContentDefault: 'Treść Wielkiej Brytanii (domyślnie)',
  SpanishContentDefault: 'Treść hiszpańska (domyślnie)',
  FrenchContentDefault: 'Treść francuska (domyślnie)',
  NederlandsContentDefault: 'Treść Nederlands (domyślnie)',
  NorskContentDefault: 'Treść Norsk (domyślnie)',
  SvenskaContentDefault: 'Treść svenska (domyślnie)',
  ItalianContentDefault: 'Treść włoska (domyślnie)',
  PolishContentDefault: 'Treść polska (domyślnie)',
  DanskCategory: 'Kategoria DANSK',
  GermanCategory: 'Kategoria niemiecka',
  EnglishCategory: 'Kategoria angielska',
  BritainCategory: 'Kategoria Wielkiej Brytanii',
  SpanishCategory: 'Kategoria hiszpańska',
  FrenchCategory: 'Kategoria francuska',
  NederlandsCategory: 'Kategoria Nederlands',
  NorskCategory: 'Kategoria Norsk',
  SvenskaCategory: 'Kategoria svenska',
  ItalianCategory: 'Kategoria włoska',
  PolishCategory: 'Kategoria polska',
  DanskCategoryDefault: 'Kategoria DANSK (domyślnie)',
  GermanCategoryDefault: 'Kategoria niemiecka (domyślnie)',
  EnglishCategoryDefault: 'Kategoria angielska (domyślnie)',
  BritainCategoryDefault: 'Kategoria Wielkiej Brytanii (domyślnie)',
  SpanishCategoryDefault: 'Kategoria hiszpańska (domyślnie)',
  FrenchCategoryDefault: 'Kategoria francuska (domyślnie)',
  NederlandsCategoryDefault: 'Kategoria Nederlands (domyślnie)',
  NorskCategoryDefault: 'Kategoria Norsk (domyślnie)',
  SvenskaCategoryDefault: 'Kategoria svenska (domyślnie)',
  ItalianCategoryDefault: 'Kategoria włoska (domyślnie)',
  PolishCategoryDefault: 'Kategoria polska (domyślnie)',
  DanskSubcategory: 'Podkategoria DANSK',
  GermanSubcategory: 'Niemiecka podkategoria',
  EnglishSubcategory: 'Kategoria angielska',
  BritainSubcategory: 'Kategoria Wielkiej Brytanii',
  SpanishSubcategory: 'Hiszpańska podkategoria',
  FrenchSubcategory: 'Francuska podkategoria',
  NederlandsSubcategory: 'Podkategoria Nederlands',
  NorskSubcategory: 'Podkategoria NORSK',
  SvenskaSubcategory: 'Podkategoria Svenska',
  ItalianSubcategory: 'Podkategoria włoska',
  PolishSubcategory: 'Podkategoria polska',
  DanskSubcategoryDefault: 'Podkategoria DANSK (domyślnie)',
  GermanSubcategoryDefault: 'Niemiecka podkategoria (domyślnie)',
  EnglishSubcategoryDefault: 'Angielska podkategoria (domyślnie)',
  BritainSubcategoryDefault: 'Podkategoria Wielkiej Brytanii (domyślnie)',
  SpanishSubcategoryDefault: 'Hiszpańska podkategoria (domyślnie)',
  FrenchSubcategoryDefault: 'Francuska podkategoria (domyślnie)',
  NederlandsSubcategoryDefault: 'Podkategoria Nederlands (domyślnie)',
  NorskSubcategoryDefault: 'Podkategoria Norsk (domyślnie)',
  SvenskaSubcategoryDefault: 'Podkategoria Svenska (domyślnie)',
  ItalianSubcategoryDefault: 'Podkategoria włoska (domyślnie)',
  PolishSubcategoryDefault: 'Podkategoria polska (domyślnie)',
  priorSignatures: 'Wcześniejsze podpisy',
  damageWaiverSignature: 'Podpis zwolnienia uszkodzeń',
  waterReleaseSignature: 'Podpis uwalniania wody',
  errors,
  themes,
};
