import { API, getPermittedMenuConfig, useEntity, useNotification, User } from 'connex-cds';
import React from 'react';
import { matchPath, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FullStory } from './FullStory';
import { HeaderAndSidebar } from './HeaderAndSidebar';
import { TrackitWrapper } from './trackit-wrapper/TrackitWrapper';
import { IFrameAuthenticated } from './views/apps/entity-ref';
import menuConfig from './views/apps/entity-ref/menu-config';
import { Haulers } from './views/apps/entity-ref/mobile-ticket/haulers/Haulers';
import MobileTicket from './views/apps/entity-ref/mobile-ticket/MobileTicket';
import { RegisterRoutes } from './views/apps/entity-ref/mobile-ticket/register/Register';
import GeneralSetup from './views/apps/entity-ref/mobile-ticket/setup/general/GeneralSetup';
import { DynamicSetup } from './views/apps/entity-ref/mobile-ticket/setup/master-data/dynamic-setup/DynamicSetup';
import { Setup } from './views/apps/entity-ref/mobile-ticket/setup/Setup';
import { TruckNumber } from './views/apps/entity-ref/mobile-ticket/truck-number/TruckNumber';
import { Users } from './views/apps/entity-ref/mobile-ticket/users/Users';
import { withVersionHandler } from './withVersionHandler';
import { PublicSummaryRoutes } from './views/apps/entity-ref/mobile-ticket/public-summary/PublicSummary';

const InitialRoute = () => {
  const { entityRef } = useEntity();
  const userContext = User.useUserContext();
  const permittedMenuConfig = getPermittedMenuConfig({ menuConfig, userContext });

  const defaultDestination = permittedMenuConfig.sidebarOptions[0];
  if (!entityRef || !defaultDestination) return null;
  return <Navigate to={`/${entityRef}${defaultDestination.path}`} replace />;
};

const RedirectToSelectedEntity = () => {
  const { entityRef } = useEntity();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!entityRef) return;

    // if the entityRef changes navigate to the same route with the new entityRef
    if (entityRef && !location.pathname.includes(entityRef)) {
      const matchWithEntityRef = matchPath(`/:entityRef/*`, location.pathname);

      if (matchWithEntityRef?.params?.entityRef) {
        const newPathname = matchWithEntityRef.pathname.replace(matchWithEntityRef.params.entityRef, entityRef);
        navigate(newPathname);
        return;
      }

      navigate(`/${entityRef}`);
    }
  }, [entityRef, location, navigate]);
  return (
    <IFrameAuthenticated>
      <Outlet />
    </IFrameAuthenticated>
  );
};

export const AppRoutes = () => {
  const notify = useNotification();

  React.useEffect(() => {
    API.setErrorHandler(msg => notify.error(msg));
  }, [notify]);

  return (
    <Routes>
      <Route path="/register/:entityRef/:data/*" element={<RegisterRoutes />} />
      <Route path="/summary/:data/*" element={<PublicSummaryRoutes />} />
      <Route path="" element={<RedirectToSelectedEntity />}>
        <Route path=":entityRef/*" element={<IFrameAuthenticated />}>
          <Route element={<HeaderAndSidebar />}>
            <Route element={<FullStory />}>
              <Route path="" element={<InitialRoute />} exact />
              <Route path="haulers/*" element={<Haulers />} />
              <Route path="users/*" element={<Users />} />
              <Route path="setup/*">
                <Route path="" element={<Setup />} exact />
                <Route path="master-data/:typeId/*" element={<DynamicSetup />} />
                <Route path="general/:typeId/*" element={<GeneralSetup />} />
              </Route>
            </Route>
            <Route path="driver/*" element={<TrackitWrapper />}>
              <Route path="" exact element={<TruckNumber />} />
              <Route path=":truckNumber/*" element={<MobileTicket driverExperience />} />
            </Route>
          </Route>
          <Route path=":truckNumber" element={<FullStory isOuterScript={true} />}>
            <Route path="mt/*" element={<MobileTicket />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default withVersionHandler(AppRoutes);
