import { Core, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useCompany } from './MasterDataProvider';
import { useTestTicket } from './useTestTicket';

const { useTranslateMessage } = Localization;

export const WaitingForTicket = ({ setTicketRef, isRetrying, busy }) => {
  const { createTicket, busy: createTicketBusy } = useTestTicket({ setTicketRef });
  const { truckNumber } = useParams();
  const [isPump, setIsPump] = React.useState(false);

  const handleClick = React.useCallback(() => {
    createTicket(isPump);
  }, [createTicket, isPump]);

  const entity = useCompany();
  const translateMessage = useTranslateMessage();

  const allowCreateTestTicket = React.useMemo(() => {
    const hostname = window.location.hostname;
    return entity?.data?.isTestEntity || hostname.includes('localhost') || hostname.includes('.develop.');
  }, [entity?.data?.isTestEntity]);

  const ticketForTrucktruckNumber = React.useMemo(
    () => translateMessage('createTestTicketForTruck'),
    [translateMessage]
  );

  return (
    <Core.Spinner spin={busy}>
      <div
        style={{
          paddingTop: '10%',
          display: 'flex',
          // gridGap: '15px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div style={{ height: '50px', textAlign: 'center' }}>
          {isRetrying ? (
            <i className="fa-light fa-circle-notch fa-spin" style={{ position: 'absolute', top: '5px', left: '5px' }} />
          ) : null}
          <Typography.H3>
            <Localization.Translate stringId="waitingForTicket" data-testid="waitingForTicket" />
          </Typography.H3>
          {allowCreateTestTicket && (
            <div className="actions" style={{ marginTop: '50px' }}>
              <Core.Button
                stringId={`${ticketForTrucktruckNumber} ${truckNumber}`}
                data-testid="ignore-me"
                onClick={handleClick}
                type="primary"
                loading={createTicketBusy}
              />
              <div style={{ marginTop: '20px' }}>
                <Core.Checkbox onChange={setIsPump} value={isPump}>
                  Pump
                </Core.Checkbox>
              </div>
            </div>
          )}
        </div>
      </div>
    </Core.Spinner>
  );
};
