const accumulator = {
  da: {},
  de: {},
  'en-US': {},
  'en-GB': {},
  'es-CO': {},
  'fr-FR': {},
  'nl-NL': {},
  'no-NO': {},
  'sv-SE': {},
};
const reduce = (acc, cur) => ({ ...acc, [cur?.code]: { ...acc[cur?.code], [cur?.token]: cur?.value } });

export const mergeMessagesCallback =
  ({ setMessages, setIsCatchingMessages, localMessages }) =>
  entityMessages => {
    const mergedMessages = Object.keys(localMessages).reduce(
      (acc, cur) => ({ ...acc, [cur]: { ...acc[cur], ...entityMessages[cur] } }),
      localMessages
    );

    setIsCatchingMessages(false);
    setMessages(mergedMessages);
  };

export const mergeMessages = ({ entityMessages: _entityMessages, localMessages }) => {
  if (!_entityMessages || !_entityMessages?.length) {
    return localMessages;
  }

  const entityMessages = _entityMessages.reduce(reduce, accumulator);

  const mergedMessages = Object.keys(localMessages).reduce(
    (acc, cur) => ({ ...acc, [cur]: { ...acc[cur], ...entityMessages[cur] } }),
    localMessages
  );

  return mergedMessages;
};
