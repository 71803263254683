import { css } from 'styled-components';

export default css`
  &&& {
    &.column,
    .row {
      gap: 0;
    }
    .products-and-services.column {
      margin-right: 5px;
    }
    .signature-section.row {
      margin-top: 5px;
    }

    .header {
      margin: 20px 10px;
      height: 30px;
      align-items: center;
      button {
        &:first-child {
          margin-right: 20px;
        }
      }
      h3 {
        margin: 0;
      }
    }
  }
`;
