import { DateTime } from './DateTime';
import { Gt } from './Gt';
import { If } from './If';
import { Or } from './Or';
import { Path } from './Path';
import { PrimaryLineItem } from './PrimaryLineItem';
import { Uom } from './Uom';

// Instantiate the handlers
const handlers = [
  new PrimaryLineItem(),
  new Or(),
  new If(),
  new Gt(),
  new Uom(),
  new DateTime(),
  new Path(), // This should remain last.
];

// Chain the handlers
for (let index = 0; index < handlers?.length; index++) {
  const nextHandler = handlers[index + 1];
  handlers[index].setNext(nextHandler);
}

export const processToken = ({ token, context, util }) => {
  return handlers[0].handle({ token, context, util });
};
