import cn from 'classnames';
import { Core, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useCompanySetup } from '../../../MasterDataProvider';
import { useTicketContext } from '../../../TicketContext';
import { Activity } from './activity/Activity';
import { Comments } from './comments/Comments';
import { DynamicWaiver } from './dynamic-waiver/DynamicWaiver';
import { FinalizeSection } from './finalize-section/FinalizeSection';
import { Header } from './header/Header';
import { LogisticalInfo } from './logistical-info/LogisticalInfo';
import { PriorSignatures } from './prior-signatures/PriorSignatures';

const Styled = styled.div`
  padding: 5px;
  & > * {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .header {
    margin: 10px 5px;
    height: 20px;
    align-items: center;
    h3 {
      margin: 0;
    }
  }
`;

export const AcceptanceMobile = ({
  handleBackClick,
  signed,
  flow,
  activeFormsAndWaivers,
  entitySupportedLanguages,
}) => {
  const { finalized, submitted } = useTicketContext();
  const { values } = Form.useFormContext();
  const companySetup = useCompanySetup();

  const showDynamicWaiver = React.useMemo(() => {
    return !!companySetup?.data?.isDynamicWaiverEnabled && activeFormsAndWaivers?.length !== 0;
  }, [activeFormsAndWaivers?.length, companySetup?.data?.isDynamicWaiverEnabled]);

  const showPriorSignatures = React.useMemo(() => {
    return values?.damageWaiverSignature?.image || values?.waterReleaseSignature?.image;
  }, [values.damageWaiverSignature, values.waterReleaseSignature]);

  return (
    <Styled className={cn('acceptance-mobile')}>
      {finalized && (
        <div className="header">
          {signed || submitted ? null : (
            <Core.Button
              size="small"
              type="primary"
              stringId="back"
              data-testid="back-button"
              onClick={handleBackClick}
            />
          )}
          <Header flow={flow} signed={signed} entitySupportedLanguages={entitySupportedLanguages} />
        </div>
      )}
      {finalized && (
        <div style={{ padding: '0px 10px' }}>
          <Typography.H3>
            <Localization.Translate stringId="finalize" data-testid="acceptance-title" />
          </Typography.H3>
        </div>
      )}
      <LogisticalInfo ticket={values} />
      <Activity mobileLayout />
      <Comments />
      {showPriorSignatures && <PriorSignatures />}
      {showDynamicWaiver && <DynamicWaiver />}
      <FinalizeSection ticketId={values.id} flow={flow} />
    </Styled>
  );
};
