import cn from 'classnames';
import { Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from '../style';

const { Column, Row, Container } = Layout;
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const ReturnedMaterialsDesktop = ({
  mixInfo,
  concreteOnTruckUom,
  disabled,
  options,
  reasonCodeSetup,
  sensorUnable,
  sourceOptions,
  showSource,
}) => {
  const {
    Components: { ReturnReason, ReturnConcreteOnTruck, DriverDidNotAdd, ReturnConcreteSource },
  } = Form.useFormContext();

  return (
    <Styled className={cn('returned-materials')}>
      <Column>
        <Row>
          <Container flex={1}>
            <Column>
              <div className="body">
                <Translate stringId="mixIdLabel" data-testid="mixIdDescriptionLabel" /> :{' '}
                <span data-testid="mixIdDescriptionValue"> {mixInfo.mix} </span>
              </div>
              <div className="body">
                <Translate stringId="loadedQtyLabel" data-testid="loadedQtyLabel" /> :{' '}
                <span data-testid="loadedQtyValue"> {mixInfo.loadedQty} </span>
              </div>
            </Column>
          </Container>
        </Row>
        <Row>
          <Column>
            <ReturnConcreteOnTruck uomCode={concreteOnTruckUom} disabled={disabled} />
          </Column>
        </Row>
        <Column>
          <div className="row">
            {options?.length > 0 && (
              <ReturnReason options={options} busy={reasonCodeSetup.isLoading} showSearch={false} disabled={disabled} />
            )}
            {showSource && <ReturnConcreteSource options={sourceOptions} disabled={true} />}
          </div>
        </Column>
        <Row>
          <Column>
            <div className="row">
              <DriverDidNotAdd />
              {sensorUnable && (
                <Typography.Subtitle className="sensorUnable">
                  <Translate stringId="sensorUnable" />
                </Typography.Subtitle>
              )}
            </div>
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
