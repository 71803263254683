import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import { TotalPumpedQuantity } from './TotalPumpedQuantity';
import { WaterAdd } from './WaterAdd';
import { WaterRelease } from './WaterRelease';
import { DamageWaiverAck } from './DamageWaiverAck';

const Styled = styled.div`
  &&& {
    margin-left: 5px;
    margin-right: -5px;
    & > * {
      &:not(:first-child) {
        margin-top: 3px;
      }
    }
  }
`;

export const ActivityMobile = ({ includeProductsServices, leftOverConcreteUom }) => {
  return (
    <Styled className={cn('activity-detail')}>
      <DamageWaiverAck />
      <WaterAdd />
      <WaterRelease />
      {includeProductsServices && <ProductsServices />}
      <ReturnedMaterial leftOverConcreteUom={leftOverConcreteUom} />
      <TotalPumpedQuantity />
    </Styled>
  );
};
