import { http } from 'connex-cds';

export default {
  getEntity: ({ entityRef, truckNumber }) =>
    http.get({
      apiName: 'admin',
      path: `/entities/${entityRef}?cb=${Date.now()}${truckNumber ? `&truckNumber=${truckNumber}` : ''}`,
    }),
  getTranslations: ({ entityRef }) => http.get({ apiName: 'admin', path: `/language-tokens/sources/${entityRef}` }),
};
