import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../../../../../../util/useViewport';
import style from './style';
import { Typography, Layout, Localization, Drawer } from 'connex-cds';
import { PriorSignaturesModal } from './PriorSignaturesModal';

const Styled = styled(Layout.Container)`
  ${style}
`;

export const PriorSignatures = ({ data }) => {
  const { openDrawer } = Drawer.useDrawerContext();

  const dimensions = useViewport();

  const handleClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'priorSignatures',
      component: <PriorSignaturesModal />,
      width: Math.min(750, dimensions?.width),
    });
  }, [data, dimensions?.width, openDrawer]);

  return (
    <Styled onClick={handleClick}>
      <Layout.Row style={{ justifyContent: 'space-between' }}>
        <div className="left">
          <Typography.Subtitle>
            <Localization.Translate stringId="priorSignatures" data-testid="prior-signatures-label" />
          </Typography.Subtitle>
        </div>
        <div className="right">
          <Typography.Subtitle>
            <i className="fa-regular fa-angle-right" />
          </Typography.Subtitle>
        </div>
      </Layout.Row>
    </Styled>
  );
};
