import cn from 'classnames';
import React from 'react';

export const icons = {
  default: <i className="fa-solid fa-circle" />,
  ok: <i className="fa-light fa-circle-check" />,
  mandatory: <i className="fa-light fa-triangle-exclamation" />,
  error: <i className="fa-light fa-circle-xmark" />,
  disabled: <i className="fa-light fa-circle" />,
};

export const STEP_STATUS = {
  DEFAULT: 'default',
  OK: 'ok',
  MANDATORY: 'mandatory',
  ERROR: 'error',
};

export const WizardSteps = ({ steps, currentStep, onClick, labelClassname = '' }) => {
  const handleStepClick = React.useCallback(
    step => {
      if (step.disabled) return;

      onClick(step.id);
    },
    [onClick]
  );

  return (
    <div className="overview">
      {steps.map(step => {
        return (
          <div
            className={cn('step', step.status, {
              disabled: step.disabled,
              active: currentStep === step.id,
            })}
            onClick={() => handleStepClick(step)}
          >
            <div data-testid={`${step.testId}-pg-nav-emptyLabel`} className="emptyLabel"></div>
            <div data-testid={`${step.testId}-pg-nav-icon`} className="icon">
              {step.disabled && step.status !== STEP_STATUS.MANDATORY ? icons.disabled : icons[step.status]}
            </div>
            <div data-testid={`${step.testId}-pg-nav-label`} className={`label ${labelClassname}`}>
              {step.title}
            </div>
          </div>
        );
      })}
    </div>
  );
};
