import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, Core } from 'connex-cds';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import localMessages from './i18n';
import { mergeMessages } from './i18n/entitySpecific';
import menuConfig from './views/apps/entity-ref/menu-config';
import { useEntityTranslations } from './views/apps/entity-ref/mobile-ticket/MasterDataProvider';

const queryClient = new QueryClient();

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);
  const { data: entityMessages, setEntityRef, loading: fetchingEntityMessages } = useEntityTranslations();

  const handleMessageEvent = React.useCallback(
    event => {
      const type = event?.data?.type;
      if (['entity-context', 'req-entity-context'].includes(type)) {
        setEntityRef(event?.data?.token?.entityRef);
      }
    },
    [setEntityRef]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  const messages = React.useMemo(() => mergeMessages({ entityMessages, localMessages }), [entityMessages]);

  return (
    <QueryClientProvider client={queryClient}>
      <Core.Spinner spin={isConfiguring || fetchingEntityMessages}>
        <BrowserRouter>
          <ConnexDesignSystem
            messages={messages}
            appId="mt"
            breakpoints={[950]}
            analytics={false}
            menuConfig={menuConfig}
            contentOnly={true}
            queryClient={queryClient}
          >
            <AppRoutes />
          </ConnexDesignSystem>
        </BrowserRouter>
      </Core.Spinner>
    </QueryClientProvider>
  );
};

export default App;
