import { Form } from 'connex-cds';
import { find } from 'lodash';
import * as Yup from 'yup';
import { FILTER_STRATEGY } from './standard';

const { FIELD_TYPES } = Form;

const fields = (countries, setupVehicleTypes) => ({
  icon: <i className="icon fa-thin fa-truck-container" />,
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    description: Yup.string().required('required'),
    vehicleTypes: Yup.string().required('required'),
    status: Yup.string().required('required'),
  }),
  fields: [
    { path: 'id', labelStringId: 'id', testId: 'id', dataType: FIELD_TYPES.string },
    { path: 'description', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'vehicleTypes',
      labelStringId: 'vehicleType',
      testId: 'vehicleTypes',
      dataType: FIELD_TYPES.string,
      listTypeId: 'vehicle-type',
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'description',
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {setupVehicleTypes?.map?.(vehicleType => (
              <option value={vehicleType.id} key={vehicleType.id}>
                {' '}
                {vehicleType.description}
              </option>
            ))}
          </select>
        );
      },
      formatter: props => (
        <div data-testclass={'cell-column-vehicleTypes'} data-testid={`row-${props.row.crn}-column-vehicleTypes`}>
          {find(setupVehicleTypes, { id: props.row.vehicleTypes })?.description}
        </div>
      ),
    },
    {
      path: 'countries',
      labelStringId: 'country',
      testId: 'countries',
      dataType: FIELD_TYPES.string,
      listValues: [],
      listValuePath: 'id',
      listDisplayPath: 'label',
      allowClear: true,
      filterFn: (item, filterStrings) => {
        return item?.includes?.(filterStrings[0]);
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {countries?.map?.(country => (
              <option value={country.id} key={country.id}>
                {' '}
                {country.label}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
});

export default fields;
