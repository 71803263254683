import { css } from 'styled-components';

export default css`
  flex-grow: 1;
  .dynamic-waiver-value {
    padding: 10px;
    height: 80vh;
    overflow: auto;
    font-family: 'Work Sans';
    white-space: pre-wrap;
  }
`;
