import { css } from 'styled-components';

export default css`
  .row {
    @media (min-width: 951px) {
      display: flex;
      width: 100%;
      & > * {
        &:not(:first-child) {
          margin-left: 10px;
        }
        flex: 1;
      }
    }
    .sensorUnable {
      color: #ff0000;
    }
  }
`;
